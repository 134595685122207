import React from 'react';
import { Box } from '@mui/material';

interface GlassCardProps {
  className?: string;
  children?: React.ReactNode;
  restProps?: any;
}

const GlassCard = (props: GlassCardProps) => {
  const { children, className, ...restProps } = props;
  return (
    <Box
      className={className}
      sx={{
        borderRadius: '5px',
        bgcolor: 'background.white',
        border: '1px solid',
        borderColor: 'border.main',
        boxShadow: '0 6px 12px rgb(140 152 164 / 8%)',
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default GlassCard;
