// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import paginationReducer from './paginationSlice.ts';
import createSagaMiddleware from 'redux-saga';
import projectsReducer from '../redux/reducers/projectsReducer.ts';
import projectsSaga from '../redux/sagas/projectsSaga.ts';
import loaderReducer from './loaderSlice.ts';
import tabsReducer from './tabsSlice.ts';
import settingsReducer from './settingsSlice.ts';

const sagaMiddleware = createSagaMiddleware();


export const store = configureStore({
  reducer: {
    pagination: paginationReducer,
    loader: loaderReducer,
     tabs: tabsReducer,
    settings: settingsReducer,
    projects: projectsReducer,
  },middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});
sagaMiddleware.run(projectsSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
