import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE;
const Token = localStorage.getItem("token");

//token request
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    authorization: `Bearer ${Token}`,
  },
});

//without token request
export const getRequest = axios.create({
  baseURL: BASE_URL,
});



