import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
    try {
      const serializedState = localStorage.getItem('settings');
      return serializedState ? JSON.parse(serializedState) : undefined;
    } catch (err) {
      return undefined;
    }
  };

const initialState = loadState() || {
  language: "en",
  dateFormat: "DD-MM-YYYY",
  currency: "USD",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setDateFormat: (state, action) => {
      state.dateFormat = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
});

export const { setLanguage, setDateFormat, setCurrency } = settingsSlice.actions;

export default settingsSlice.reducer;
