import React from "react";
import Box from "@mui/material/Box";
import { Menu, Button } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import MuiMenuItem from "../_mui/MuiMenuItem";
import { Icon } from "@iconify/react";
import SideBarIconButton from "../layout/SideBarIconButton.tsx";
import SideBarMenuButton from "../layout/SideBarMenuButton.tsx";

const AccountMenu = [
  {
    title: "Holidays",
    icon: <Icon icon="mynaui:aeroplane" />,
    path: "/fm/holidays",
  },

  {
    title: "General Documents",
    icon: <Icon icon="ic:baseline-drive-folder-upload" />,
    path: "/fm/general-documents",
  },

  {
    title: "Approval Nodes",
    icon: <Icon icon="tdesign:drag-drop" />,
    path: "/fm/approval-node",
  },

  {
    title: "Calendar Booking",
    icon: <Icon icon="uil:calendar-alt" />,
    path: "/fm/calendar-booking",
  },
  {
    title: "Form Builder",
    icon: <Icon icon="carbon:data-format" />,
    path: "/fm/template",
  },
  {
    title: "Questions Builder",
    icon: <Icon icon="solar:question-circle-broken" />,
    path: "/fm/questions",
  },
  {
    title: "Mail Template",
    icon: <Icon icon="ic:outline-contact-mail" />,
    path: "/mailTemplate",
  },
  {
    title: "Text Editor",
    icon: <Icon icon="ic:baseline-text-fields" />,
    path: "/text-editor",
  },
];

const SidebarSettingsDropdown = ({ openToggle }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="w-full">
      {!openToggle ? (
        <SideBarMenuButton
          onClick={handleClick}
          name="Extra Links"
          icon={<Icon icon="fluent:link-multiple-16-regular" />}
        />
      ) : (
        <SideBarIconButton
          name="Extra Links"
          icon={<Icon icon="fluent:link-multiple-16-regular" />}
          onClick={handleClick}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 36%)",
            "& .MuiList-root": {
              padding: "7px",
            },
          },
        }}
      >
        {AccountMenu?.map((menu, i) => (
          <MuiMenuItem
            onClick={() => navigate(menu.path)}
            key={i}
            name={menu.title}
            icon={menu.icon}
          />
        ))}
      </Menu>
    </Box>
  );
};

export default SidebarSettingsDropdown;
