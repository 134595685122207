import React from "react";
import {
  Box,
  Checkbox,
  Tooltip,
  TableRow,
  Button,
  Table,
  TableCell,
  TableBody,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { AttendanceText, FileNameTypeFunc } from "../../utils/JsUtils";

const colorClasses = {
  green: "text-green-700 bg-green-200",
  red: "text-red-700 bg-red-200",
  blue: "text-blue-700 bg-blue-200",
  black: "text-black bg-gray-200",
  purple: "text-purple-700 bg-purple-200",
  orange: "text-orange-700 bg-orange-200",
  teal: "text-teal-700 bg-teal-200",
  indigo: "text-indigo-700 bg-indigo-200",
  active: "active",
  // Add more colors as needed
};

const TextColors = {
  green: "text-green-700",
  red: "text-red-700",
  blue: "text-blue-700",
};

const ViewField = ({ title, label }) => {
  return (
    <Box className="w-full">
      <Box className="opacity-60">{label}</Box>
      <Box sx={{ fontWeight: 500 }}>{title}</Box>
    </Box>
  );
};

const FileCard = ({ title, size }) => {
  return (
    <Box
      className="flex flex-row items-center w-full p-3 py-5"
      sx={{
        bgcolor: "background.white",
        border: "1px solid",
        borderColor: "border.main",
        borderRadius: "15px",
      }}
    >
      <Box className="text-4xl me-5">
        <Icon icon="pepicons-print:file" />
      </Box>
      <Box>
        <Box className="font-semibold">{title}</Box>
        <Box className="opacity-50">{size}</Box>
      </Box>
    </Box>
  );
};

const StatusBadge = ({ title, type }) => {
  const badgeClass = colorClasses[type] || "text-gray-700 bg-gray-200";

  return (
    <>
      <Box
        className={`${badgeClass} px-1.5  inline-flex items-center font-semibold`}
        sx={{
          fontSize: "10px",
          borderRadius: "25px",
          textTransform: "uppercase",
        }}
      >
        <span>
          <Icon icon="radix-icons:dot-filled" />
        </span>{" "}
        {title}
      </Box>
    </>
  );
};

const StatusBadgeWithClick = ({ title, type, ...props }) => {
  const badgeClass = colorClasses[type] || "text-gray-700 bg-gray-200";

  return (
    <>
      <Button
        className={`${badgeClass} px-2 py-0.5  d-flex items-center gap-1 font-semibold`}
        sx={{
          fontSize: "10px",
          textTransform: "uppercase",
          borderRadius: "15px",
          "&.active": { bgcolor: "primary.main", color: "text.white" },
        }}
        {...props}
      >
        <span>
          <Icon icon="radix-icons:dot-filled" />
        </span>
        {title}
      </Button>
    </>
  );
};

const TextColor = ({ title, color }) => {
  const colors = {
    red: "text-red-500",
    green: "text-green-500",
  };
  return (
    <>
      <Box sx={{ color: "text.main" }} className={colors[color]}>
        {title}
      </Box>
    </>
  );
};

const CheckedInTable = ({ color, defaultChecked }) => {
  return (
    <Box>
      <Checkbox
        defaultChecked={defaultChecked}
        disabled
        color={color}
        sx={{
          "&.MuiCheckbox-colorError": {
            color: "red",
          },
          "&.MuiCheckbox-colorSuccess": {
            color: "green",
          },
        }}
      />
    </Box>
  );
};

const LeaveColors = ({ text, tooltipOff }) => {
  var AttendanceLoop = AttendanceText(text);

  const BoxTitle = ({ name, className }) => {
    return (
      <Box
        sx={{
          fontSize: "22px",
          height: "20px",
          lineHeight: "20px",
        }}
        className={className}
      >
        {name}
      </Box>
    );
  };

  return (
    <Box>
      {!tooltipOff ? (
        <Tooltip title={AttendanceLoop.name} arrow>
          <Box>
            <BoxTitle
              name={AttendanceLoop.icon}
              className={AttendanceLoop.textColor}
              tools
            />
          </Box>
        </Tooltip>
      ) : (
        <BoxTitle
          name={AttendanceLoop.icon}
          className={AttendanceLoop.textColor}
          tools
        />
      )}
    </Box>
  );
};

const LeaveText = ({ text, tooltipOff }) => {
  var AttendanceLoop = AttendanceText(text);

  const TextBoxCustomize = ({ className, name }) => {
    return (
      <Box
        sx={{
          fontSize: "11px",
          color: "text.white",
          width: "20px",
          height: "20px",
          textAlign: "center",
          lineHeight: "20px",
          borderRadius: "3px",
        }}
        className={className}
      >
        {name}
      </Box>
    );
  };
  return (
    <Box>
      {tooltipOff ? (
        <TextBoxCustomize
          className={AttendanceLoop.bg}
          name={AttendanceLoop.shortName}
        />
      ) : (
        <Tooltip title={AttendanceLoop.name} arrow>
          <Box>
            <TextBoxCustomize
              className={AttendanceLoop.bg}
              name={AttendanceLoop.shortName}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

const Indicators = ({ small, title, open }) => {
  return (
    <Box>
      <Box className="flex items-center">
        {open ? (
          <LeaveText tooltipOff={true} text={small} />
        ) : (
          <LeaveColors tooltipOff={true} text={small} />
        )}
        <Box className="opacity-65 ms-2"> {title}</Box>
      </Box>
    </Box>
  );
};

const FilledTextPercentage = ({ text }) => {
  return (
    <Box
      className="flex justify-center items-center"
      sx={{
        position: "relative",
        width: "100%",
        border: "1px solid",
        borderColor: "secondary.main",
        height: "25px",
      }}
    >
      <Box
        sx={{
          fontWeight: 700,
          color: "text.dark",
          position: "relative",
          zIndex: 1,
        }}
      >
        {" "}
        {text}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          opacity: 0.7,
          width: text,
        }}
        className="bg-gradient-to-r from-yellow-500 to-green-300 ..."
      ></Box>
    </Box>
  );
};

const ViewTable = ({ rows, disableBorder }) => {
  return (
    <>
      <Table sx={{}}>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              key={i}
              sx={{
                "& .MuiTableCell-root": {
                  border: !disableBorder && "1px solid",
                  borderColor: "border.main",
                  padding: "8px",
                  fontSize: "inherit",
                },
              }}
            >
              <TableCell className="font-semibold">{row.label}</TableCell>
              <TableCell>{row.title}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const FileNameType = ({ name, format }) => {
  var FileTypeLoop = FileNameTypeFunc(format);
  return (
    <Box className="flex items-center gap-3">
      <Box className={`text-2xl ${FileTypeLoop.textColor} `}>
        {FileTypeLoop.icon}
      </Box>
      <Box className="font-semibold">{name}</Box>
    </Box>
  );
};

const OrderNotesTooltip = ({ title, color }) => {
  return (
    <Box>
      <Tooltip title={title} arrow placement="top">
        <Box className={`text-2xl ${TextColors[color]}`}>
          <Icon icon="carbon:information" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export {
  ViewField,
  StatusBadge,
  FileCard,
  TextColor,
  CheckedInTable,
  LeaveColors,
  Indicators,
  FilledTextPercentage,
  LeaveText,
  ViewTable,
  FileNameType,
  StatusBadgeWithClick,
  OrderNotesTooltip,
};
