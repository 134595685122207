import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SidebarMenu from "./helpers/SidebarMenu";
import PageMenu from "./PageMenu";
import { Icon } from "@iconify/react";
import { Nav } from "../utils";
import SidebarSettingsDropdown from "./helpers/SidebarSettingsDropdown";

const EssNav = {
  id: 1,
  name: "ESS",
  icon: <Icon icon="solar:accessibility-broken" />,
  color: "#067e89",
  child: [
    {
      name: "Profile",
      icon: <Icon icon="solar:chart-broken" />,
      path: "/essPortal/dashboard",
      children: null,
    },
    {
      name: "Leave Request",
      icon: <Icon icon="streamline:database-refresh" />,
      count: "3",
      path: "/essPortal/leaveRequest",
      children: [],
    },
    {
      name: "Leave Join",
      icon: <Icon icon="streamline:definition-search-book" />,
      path: "/essPortal/leaveJoin",
      children: null,
    },

    {
      name: "Resignation",
      path: "/essPortal/resignation",
      icon: <Icon icon="streamline:bag-suitcase-1" />,
      children: [],
    },
    {
      name: "Passport Request",
      path: "/essPortal/passportRequest",
      icon: <Icon icon="streamline:receipt-check" />,
    },

    {
      name: "Document uploads",
      path: "/essPortal/documentUploads",
      icon: <Icon icon="simple-line-icons:calender" />,
      children: [],
    },
  ],
};

const Sidebar = (props) => {
  const { type, open } = props;
  const [activeMenuOpen, setActiveMenuOpen] = React.useState(false);
  const menu = localStorage.getItem("menu") || 0;

  const handleActiveMenuLeaveOpen = (i) => {
    setActiveMenuOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          minWidth: !open ? "220px" : "60px",
          flexBasis: !open ? "220px" : "60px",
          display: "flex",
          height: "100%",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-between",
          px: "0px",
          zIndex: 3,
          paddingBottom: "10px",
          overflow: !open && "hidden",
          borderRadius: !open && "0px 15px 0px 0px",
          bgcolor: !open && "background.overlay",
          border: !open && "1px solid",
          borderColor: "border.main",
          p: !open && 1,
        }}
        className="sm:hidden"
      >
        <Box
          sx={{
            height: "calc(100vh - 49px)",
            overflow: "auto",
            width: "100%",
          }}
        >
          <Stack direction="column" className="w-full" spacing={0.5}>
            {type === "ESS"
              ? EssNav.child.map((item, i) => {
                  return (
                    item.name && (
                      <SidebarMenu
                        icon={item.icon}
                        key={i}
                        name={item.name}
                        to={item.path}
                        open={open}
                      />
                    )
                  );
                })
              : Nav[menu].child?.map((item, i) => {
                  return (
                    item.name && (
                      <SidebarMenu
                        icon={item.icon}
                        key={i}
                        name={item.name}
                        to={item.path}
                        open={open}
                      />
                    )
                  );
                })}
          </Stack>
        </Box>
        <SidebarSettingsDropdown openToggle={open} />
      </Box>
      <PageMenu open={activeMenuOpen} leavePanel={handleActiveMenuLeaveOpen} />
    </>
  );
};

export default Sidebar;
