import React, { memo } from 'react';
import {
  Controller,
  Control,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';

interface FmTextFieldProps<T extends FieldValues> {
  name: string;
  control: Control<T>;
  label?: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  type?: string;
  multiline?: any;
  rows?: number;
  maxRows?: number;
  readOnly?: boolean;
  value?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputProps?: any;
}

const FmTextField = <T extends FieldValues>({
  name,
  control,
  label,
  defaultValue = '',
  rules = {},
  type = 'text',
  multiline,
  rows,
  value,
  maxRows,
  readOnly = false,
  onChange,
  disabled = false,
  inputProps = {},
}: FmTextFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <Box>
        <TextField
          {...field}
          label={
            <>
              {label}
              {rules?.required && (
                <Typography component='span' color='error'>
                  {' '}
                  *
                </Typography>
              )}
            </>
          }
          type={type}
          variant='filled'
          error={!!error}
          fullWidth
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          inputProps={readOnly ? { readOnly: true } : { ...inputProps, min: 0 }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
          disabled={disabled ? true : false}
        />
        {error ? (
          <Box sx={{ marginTop: 0.2, color: 'error.main', fontSize: '8px' }}>
            {error?.message}
          </Box>
        ) : null}
      </Box>
    )}
  />
);

export default memo(FmTextField);
