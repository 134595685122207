// src/api/API.js
import axios from 'axios';
import { userRequest, getRequest } from './RequestMethod';
import { toast } from 'react-toastify';

class API {
  constructor() {
    this.rootUrl = process.env.REACT_APP_BASE;
    this.token = localStorage.getItem('token');
  }

  handleError = (error) => {
    const errorMessage =
    error.response?.data?.error ||   error.response?.data?.errors ||  error.response?.data?.message || error.message || 'An error occurred';
    toast.error(errorMessage);
   
    // throw error; // re-throw the error after showing toast
  };

  login = async (data) => {
    try {
      const res = await getRequest.post('auth/login', data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  getAll = async (url, params) => {
    try {
      const res = await userRequest.get(url, { params: params });
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  getSingle = async (url, params) => {
    try {
      const res = await userRequest.get(`${url}/${params}`);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  // Get files function
  getFiles = async (url) => {
    try {
      const res = await axios.get(`${this.rootUrl}/${url}`, {
        responseType: 'blob',
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      if (res.status === 200) {
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        return new Promise((resolve) => {
          reader.onloadend = function () {
            resolve(reader.result);
          };
        });
      }
    } catch (err) {
      this.handleError(err);
      throw err;
    }
  };

  // Post files
  postFiles = async (url, data) => {
    try {
      const res = await axios.post(`${this.rootUrl}/${url}`, data, {
        responseType: 'blob',
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      if (res.status === 200) {
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        return new Promise((resolve) => {
          reader.onloadend = function () {
            resolve(reader.result);
          };
        });
      }
    } catch (err) {
      this.handleError(err);
      throw err;
    }
  };


  create = async (url, data) => {
    try {
      const res = await userRequest.post(url, data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  update = async (url, params, data) => {
    try {
      const res = await userRequest.put(`${url}/${params}`, data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  patch = async (url, params, data) => {
    try {
      const res = await userRequest.patch(`${url}/${params}`, data);
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  remove = async (url, params) => {
    try {
      const res = await userRequest.delete(`${url}/${params}`);
      return res;
    } catch (error) {
      this.handleError(error); 
    }
  };

  getAllEmployee = async (employeeSearchValue) => {
    try {
      const res = await userRequest.get(
        employeeSearchValue && employeeSearchValue !== ''
          ? `employees?searchKeyword=${employeeSearchValue}`
          : 'employees'
      );
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };

  getLoginUsers = async () => {
    try {
      const res = await userRequest.get('loginusers?pageSize=100&pageNumber=1');
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
  filter = async (url, params) => {
    const qparms = { ...params };
    Object.keys(qparms).forEach(
      (key) =>
        qparms[key] === undefined || (qparms[key] === '' && delete qparms[key])
    );
    try {
      const res = await userRequest.get(url, { params: qparms });
      return res;
    } catch (error) {
      this.handleError(error);
    }
  };
}

const apiInstance = new API();

export default apiInstance;
