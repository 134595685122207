import React, { useState } from 'react';
import Header from './Header';
import { Box, Button } from '@mui/material/';
import Sidebar from './Sidebar';
import Title from './page/Title';
import PageSubmenu from './page/PageSubmenu';
import Icons from '../utils/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { userLogout } from '../redux/loginSlice.ts';
import { useNavigate } from 'react-router-dom';
import MuiAlertModal from '../components/_mui/MuiAlertModal.tsx';

interface DashboardLayoutProps {
  title: string;
  children: React.ReactNode;
  actionButtons?: React.ReactNode;
  hasSubmenu?: boolean;
  menu?: any;
  parentMenu?: string;
  parentMenuUrl?: string;
  parentMenuHide?: boolean;
}

const DashboardLayout = (props: DashboardLayoutProps) => {
  const {
    title,
    children,
    actionButtons,
    hasSubmenu,
    menu,
    parentMenu,
    parentMenuUrl,
    parentMenuHide,
  } = props;

  const navigate = useNavigate();

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const HandleMenuCollapse = () => {
    setMenuCollapse(!menuCollapse);
  };

  const handleLogout = () => {
    dispatch(userLogout({ user, token, navigate }));
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setIsModalOpen(false);
    }
  };

  const ImpersonatorCondition = user?.impersonatorUserName;

  return (
    <>
      <MuiAlertModal
        open={isModalOpen}
        close={handleCloseModal}
        submitOnclick={handleLogout}
        submitText='Confirm Logout'
        desc={
          'Impersonation Mode is accessible to administrators only. Non-admin users will only see a change to their UI if an administrator makes a change'
        }
      />

      <Box
        className='flex flex-col w-full h-screen overflow-hidden sm:h-auto'
        sx={{
          border: ImpersonatorCondition && '5px solid',
          borderColor: ImpersonatorCondition && 'error.main',
        }}
      >
        <Header
          HandleMenuCollapse={HandleMenuCollapse}
          collaspeMenuState={menuCollapse}
        />
        <Box className='w-full flex flex-1 h-full' sx={{ minHeight: 0 }}>
          <Sidebar open={menuCollapse} />
          <Box className='h-full flex-1' sx={{ minWidth: 0 }}>
            <Box
              className='flex flex-col w-full h-full sm:rounded-none sm:h-auto'
              sx={{
                bgcolor: 'background.overlay',
                overflow: 'hidden',
              }}
            >
              {title && (
                <>
                  <Box
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Title title={title}>{actionButtons}</Title>
                    {hasSubmenu && (
                      <PageSubmenu
                        menu={menu}
                        parentMenu={parentMenu}
                        parentMenuUrl={parentMenuUrl}
                        parentMenuHide={parentMenuHide}
                      />
                    )}
                  </Box>
                </>
              )}
              <Box
                className='p-3 w-full flex-1  h-full overflow-auto sm:h-auto'
                sx={{ minHeight: 0 }}
              >
                {children}
              </Box>

              {ImpersonatorCondition && (
                <Box
                  className='py-1 ps-2  flex justify-between items-center text-white'
                  sx={{
                    bgcolor: 'error.main',
                    paddingRight: '70px',
                  }}
                >
                  <Box className='flex gap-3 items-center'>
                    <p className='text-2xl'>{Icons.impersonator}</p>
                    <p>
                      You are impersonating <b>{user?.impersonatorUserName}</b>
                    </p>
                  </Box>
                  <Button
                    className='text-white font-bold underline p-0'
                    onClick={handleOpenModal}
                  >
                    Leave Impersonator Mode
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
