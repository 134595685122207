import { Icon } from '@iconify/react';
import React from 'react';
interface NavItem {
  id?: number;
  name: string;
  icon: React.ReactNode;
  color?: string;
  path?: string;
  child?: NavItem[];
  count?: string;
  children?: any;
}

const Nav: NavItem[] = [
  {
    id: 1,
    name: 'HRMS',
    icon: <Icon icon='solar:accessibility-broken' />,
    color: '#067e89',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/hrms/dashboard',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Employee Master',
        icon: <Icon icon='fluent:people-list-16-regular' />,
        count: '3',
        path: '/hrms/employee',
        children: [
          {
            name: 'Employee Dashboard',
            path: '/hrms/employee/dashboard',
          },
          {
            name: 'Employees',
            path: '/hrms/employee/master-list',
          },

          {
            name: 'Attendance',
            path: '/hrms/employee/attendance-summary',
          },
          {
            name: 'Employee Attendance Calculations',
            path: '/hrms/employee/attendance',
          },
          {
            name: 'Leave Request',
            path: '/hrms/employee/leave-request',
          },
          // {
          //   name: "Leave Transaction",
          //   path: "/hrms/employee-master/leave-transaction",
          // },
          {
            name: 'Leave Join',
            path: '/hrms/employee/leave-join',
          },
          {
            name: 'Passport Request',
            path: '/hrms/employee/passport-request',
          },
          {
            name: 'Employee Exit / Cancellation',
            path: '/hrms/employee/cancellation',
          },
          {
            name: 'Final Settlements',
            path: '/hrms/employee/final-settlement',
          },

          // {
          //   name: 'Training Transaction',
          //   path: '/hrms/employee-master/training-transaction',
          // },
          {
            name: 'Certificates',
            path: '/hrms/employee/certificates',
          },
          {
            name: 'Employee Salary Calculations',
            path: '/hrms/employee/employee-salary-calculations',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },

      // {
      //   name: "Document Management",
      //   icon: <Icon icon='streamline:definition-search-book' />,
      //   path: "/hrms/document-management",
      //   children: null,
      // },

      {
        name: 'Job Requisition',
        path: '/hrms/job-requisition',
        icon: <Icon icon='fluent:line-horizontal-4-search-16-regular' />,
        children: [
          {
            name: 'Job Requisition',
            path: '/hrms/job-requisition/list',
          },
          {
            name: 'Job Recruitments',
            path: '/hrms/job-requisition/jobrecruitments',
          },
          {
            name: 'Candidates',
            path: '/hrms/job-requisition/candidates',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      // {
      //   name: 'Final Settlement',
      //   path: '/hrms/final-settlement',
      //   icon: <Icon icon='streamline:receipt-check' />,
      // },

      {
        name: 'Training and Event',
        path: '/hrms/training-event',
        icon: <Icon icon='fluent:calendar-16-regular' />,
        children: [
          {
            name: 'Training and Event',
            path: '/hrms/training-event/list',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      // {
      //    name: "Approval Workflow",
      //    path: "/hrms/approval-workflow",
      //    icon: <Icon icon="mdi:workflow-outline" />,
      // },
      // {
      //    name: "Letter Management",
      //    path: "/hrms/letter-management",
      //    icon: <Icon icon="pepicons-pencil:letter-open" />,
      // },
      {
        name: 'All Masters',
        path: '/hrms/masters',
        icon: <Icon icon='fluent:settings-16-regular' />,
        children: [
          {
            name: 'Department',
            path: '/hrms/masters/department',
          },
          {
            name: 'Designation',
            path: '/hrms/masters/designation',
          },
          {
            name: 'Location',
            path: '/hrms/masters/location',
          },
          {
            name: 'Nationality',
            path: '/hrms/masters/nationality',
          },
          {
            name: 'Region',
            path: '/hrms/masters/region',
          },
          {
            name: 'Experience Type',
            path: '/hrms/masters/experience-type',
          },
          {
            name: 'Contract Type',
            path: '/hrms/masters/contract-type',
          },
          {
            name: 'Blood Group',
            path: '/hrms/masters/blood-group',
          },
          // {
          //   name: "Certificate Type",
          //   path: "/hrms/all-masters/certificate-type",
          // },
          {
            name: 'Document Type',
            path: '/hrms/masters/document-type',
          },
          {
            name: 'Document Type Category',
            path: '/hrms/masters/document-type-category',
          },
          {
            name: 'WPS Master',
            path: '/hrms/masters/wps',
          },
          {
            name: 'Type of Cancellation',
            path: '/hrms/masters/cancellation-type',
          },
          {
            name: 'Positions',
            path: '/hrms/masters/positions',
          },
          {
            name: 'Companies',
            path: '/hrms/masters/companies',
          },
          {
            name: 'Pay Groups',
            path: '/hrms/masters/pay-groups',
          },
          {
            name: 'Cost Centers',
            path: '/hrms/masters/cost-centers',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Shift Time Master',
        path: '/hrms/shift-time-master',
        icon: <Icon icon='fluent:timer-16-regular' />,
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Department Shifts',
        path: '/hrms/department-shifts',
        icon: <Icon icon='fluent:folder-list-16-regular' />,
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Leave Configuration',
        path: '/hrms/leave-configuration',
        icon: <Icon icon='fluent:arrow-exit-20-regular' />,
        children: null,
      },
      {
        name: 'Manpower RateCard',
        icon: <Icon icon='fluent:accessibility-16-regular' />,
        path: '/hrms/manpower-ratecard',
        children: null,
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/hrms/document-management',
        children: null,
      },
    ],
  },

  {
    // id: 2,
    // name: "CRM",
    // color: "#3F51B5",
    // icon: <Icon icon="solar:archive-down-minimlistic-linear" />,
    // child: [
    //   {
    //     name: "HRMS Contact",
    //     icon: <Icon icon="mdi:contact" />,
    //   },
    //   {
    //     name: "HRMS Appoinments",
    //     icon: <Icon icon="uis:calender" />,
    //   },
    // ],
  },

  {
    // id: 3,
    // name: "ERP",
    // icon: <Icon icon="solar:armchair-broken" />,
    // color: "#FF9800",
    // child: null,
  },

  {
    id: 4,
    name: 'PR',
    icon: <Icon icon='solar:archive-down-minimlistic-linear' />,
    color: '#FF9800',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/pr/dashboard',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Employee master',
        icon: <Icon icon='fluent:people-list-16-regular' />,
        count: '3',
        path: '/pr/employee',
        children: [
          {
            name: 'Employee master',
            path: '/pr/employee/list',
          },
          {
            name: 'Passport Request',
            path: '/pr/employee/passport-request',
          },
          {
            name: 'Employee Certificates',
            path: '/pr/employee/certificates',
          },
          // {
          //   name: 'Training transaction',
          //   path: '/pr/employee-master/training-transaction',
          // },
          {
            name: 'Candidates',
            path: '/pr/employee/candidates',
          },
          {
            name: 'Passport / Visa',
            path: '/pr/employee/passport-visa',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },

      {
        name: 'All master',
        icon: <Icon icon='fluent:settings-16-regular' />,
        path: '/pr/masters',
        children: [
          {
            name: 'Card Types',
            path: '/pr/masters/card-types',
          },
          {
            name: 'Expense type',
            path: '/pr/masters/expense-type',
          },
          {
            name: 'Company Insurance Policy',
            path: '/pr/masters/policies',
          },
          {
            name: 'Company Insurance Policy Types',
            path: '/pr/masters/policy-type',
          },
          {
            name: 'End Users',
            path: '/pr/masters/end-user',
          },
          {
            name: 'Type of Products',
            path: '/pr/masters/type-of-product',
          },
          {
            name: 'Medical Insurance Locations',
            path: '/pr/masters/medical-insurance-location',
          },
          {
            name: 'Medical Insurance Status',
            path: '/pr/masters/medical-insurance-status',
          },
          {
            name: 'Medical Insurance Age Bands',
            path: '/pr/masters/medical-insurance-age-band',
          },
          {
            name: 'Medical Insurance Policy',
            path: '/pr/masters/medical-insurance-policy',
          }
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Training / Event',
        path: '/pr/training-and-event',
        icon: <Icon icon='fluent:calendar-arrow-counterclockwise-16-regular' />,
        children: [
          {
            name: 'Training / Event',
            path: '/pr/training-and-event/list',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },

      {
        name: 'Quota Employees Summary',
        path: '/pr/quota-employees-summary',
        icon: <Icon icon='fluent:slide-search-16-regular' />,
        children: [],
      },

      // {
      //   name: "Stock Reports",
      //   path: "/pr/stock-reports",
      //   icon: <Icon icon="pepicons-print:leave-off" />,
      //   children: [
      //     {
      //       name: "Uniform stocks",
      //       path: "/pr/stock-reports/uniform-stocks",
      //     },
      //     {
      //       name: "Shoe stocks",
      //       path: "/pr/stock-reports/shoe-stocks",
      //     },
      //   ],
      // },
      // {
      //   name: "Tenancy Tracker",
      //   path: "/pr/tenancy-tracker",
      //   icon: <Icon icon="pepicons-print:leave-off" />,
      //   children: null,
      // },
      // {
      //   name: "Vechicle master",
      //   icon: <Icon icon='streamline:car-taxi-1' />,
      //   path: "/pr/vechicle-master",
      //   children: [
      //     {
      //       name: "Vechicle Maintenance",
      //       path: "/pr/vechicle-master/vechicle-maintenance",
      //     },
      //     {
      //       name: "Petrol expense",
      //       path: "/pr/vechicle-master/petrol-expense",
      //     },
      //     {
      //       name: "Toll Recharge",
      //       path: "/pr/vechicle_maste/toll-recharger",
      //     },
      //   ],
      // },
      // // {
      //   name: "Document Management",
      //   icon: <Icon icon='streamline:definition-search-book' />,
      //   path: "/pr/document-management",
      //   children: null,
      // },
      {
        name: 'Cash Account management',
        icon: <Icon icon='fluent:money-16-regular' />,
        path: '/pr/cash-account-management',
        children: [
          {
            name: 'Cash Account management',
            path: '/pr/cash-account-management/list',
          },
          {
            name: 'Invoice/Transaction Management',
            path: '/pr/cash-account-management/transaction',
          },
        ],
      },
      {
        name: 'Transaction Management',
        icon: <Icon icon='fluent:wallet-credit-card-16-regular' />,
        path: '/pr/transaction',
        children: [
          {
            name: 'Invoice/Transaction Management',
            path: '/pr/transaction',
          },
        ],
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/pr/document-management',
        children: null,
      },
      {
        name: 'Company Insurance Policy',
        icon: <Icon icon='fluent:shield-task-16-regular' />,
        path: '/pr/company-insurance-policy',
        children: null,
      },
      {
        name: 'Company Medical Insurance Policy',
        icon: <Icon icon='fluent:briefcase-medical-16-regular' />,
        path: '/pr/company-medical-insurance-policy',
        children: null,
      },
      {
        name: 'Accommodation',
        icon: <Icon icon="fluent:conference-room-24-regular" />,
        path: '/pr/accommodation',
        children: null,
      },
    ],
  },
  {
    id: 5,
    name: 'PreSales',
    icon: <Icon icon='solar:armchair-broken' />,
    color: '#FF9800',
    path: '/PreSales/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/presales/dashboard',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Enquiry',
        icon: <Icon icon='fluent:mail-unread-16-regular' />,
        count: '3',
        path: '/presales/enquiry',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      // {
      //   name: 'Manage Document',
      //   icon: <Icon icon='streamline:definition-search-book' />,
      //   path: '/presales/manage-document',
      //   children: null,
      // },
      // {
      //   name: 'Bidbond /Sitevisit Notification & Collection',
      //   path: '/presales/bidbond',
      //   icon: <Icon icon='streamline:color-palette' />,
      //   children: null,
      // },
      {
        name: 'Proposal',
        path: '/presales/proposal',
        icon: <Icon icon='fluent:prompt-16-regular' />,
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },

      {
        name: 'Suppliers',
        icon: <Icon icon='fluent:vehicle-truck-profile-16-regular' />,
        path: '/presales/suppliers',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Projects',
        icon: <Icon icon='fluent:apps-16-regular' />,
        path: '/presales/projects',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Contracts',
        icon: <Icon icon='fluent:notepad-16-regular' />,
        path: '/presales/manage-contracts',
        children: [
          { name: 'CRUD Contract', path: '/presales/manage-contracts/crud' },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Clients',
        icon: <Icon icon='fluent:people-team-16-regular' />,
        path: '/presales/clients',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Tender Inspection Checklist',
        icon: <Icon icon='fluent:task-list-square-16-regular' />,
        path: '/presales/tender-inspection-checklist',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Estimation Sheet for AMC',
        icon: <Icon icon='fluent:text-grammar-wand-16-regular' />,
        path: '/presales/estimation-sheet-amc',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Estimation Sheet for MEP',
        icon: <Icon icon='fluent:book-16-regular' />,
        path: '/presales/estimation-sheet-mep',
        children: null,
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/presales/document-management',
        children: null,
      },
      {
        name: 'All master',
        icon: <Icon icon='fluent:settings-16-regular' />,
        path: '/presales/masters',
        children: [
          {
            name: 'Tender Enquiry Types',
            path: '/presales/masters/tender-enquiry-types',
          },
          {
            name: 'Tender Project Types',
            path: '/presales/masters/tender-project-types',
          },
          {
            name: 'Tender Formats',
            path: '/presales/masters/tender-formats',
          },
          {
            name: 'Countries',
            path: '/presales/masters/countries',
          },
          {
            name: 'Supplier Types',
            path: '/presales/masters/supplier-types',
          },
          {
            name: 'Tender Followup Types',
            path: '/presales/masters/tender-followup-types',
          },
          {
            name: 'Tender Status',
            path: '/presales/masters/tender-status',
          },
          {
            name: 'Contact Modes',
            path: '/presales/masters/contact-modes',
          },
          {
            name: 'Regions',
            path: '/presales/masters/regions',
          },
          {
            name: 'Buildings',
            path: '/presales/masters/buildings',
          },
          {
            name: 'Locations',
            path: '/presales/masters/locations',
          },
          {
            name: 'Departments',
            path: '/presales/masters/departments',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
    ],
  },

  {
    id: 6,
    name: 'CAFM',
    icon: <Icon icon='solar:help-broken' />,
    color: '#FF9800',
    path: '/cafm/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/cafm/dashboard',
        children: null,
      },
      {
        name: 'Tickets',
        icon: <Icon icon='fluent:ticket-diagonal-16-regular' />,
        path: '/cafm/tickets',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Inventory',
        icon: <Icon icon='fluent:building-home-16-regular' />,
        path: '/cafm/inventory-management',
      },
      {
        name: 'Incident Management',
        icon: <Icon icon='fluent:chat-warning-16-regular' />,
        path: '/cafm/incident-management',
        children: null,
      },
      {
        name: 'Service Request Management',
        icon: <Icon icon='fluent:service-bell-16-regular' />,
        path: '/cafm/service-request-management',
        children: null,
      },
      {
        name: 'Escalation management',
        icon: <Icon icon='fluent:contract-up-right-16-regular' />,
        path: '/cafm/escalation-management',
        children: null,
      },
      {
        name: 'Knowledge Management',
        icon: <Icon icon='fluent:lightbulb-filament-16-regular' />,
        path: '/cafm/knowledge-management',
        children: null,
      },
      {
        name: 'Reporting & Analytics',
        icon: <Icon icon='fluent:bug-16-regular' />,
        path: '/cafm/reporting-analytics',
        children: null,
      },
      {
        name: 'Project Division',
        icon: <Icon icon='fluent:clover-16-regular' />,
        path: '/cafm/project-division',
        children: null,
      },
      {
        name: 'All Masters',
        icon: <Icon icon='fluent:settings-16-regular' />,
        path: '/cafm/masters',
        children: [
          {
            name: 'Project BOQ Head Master',
            path: '/cafm/masters/project-boq-head-master',
          },
          {
            name: 'Project BOQ Package Master',
            path: '/cafm/masters/project-boq-package-master',
          },
          {
            name: 'Project BOQ Sub Head Master',
            path: '/cafm/masters/project-boq-sub-head-master',
          },
          {
            name: 'BOQ Line Item Master',
            path: '/cafm/masters/boq-line-item-master',
          },
        ],
      },
      {
        name: 'Asset History',
        icon: <Icon icon='fluent:layer-diagonal-16-regular' />,
        path: '/cafm/asset-history',
        children: null,
      },
    ],
  },

  {
    id: 7,
    name: 'Procurement',
    icon: <Icon icon='solar:box-broken' />,
    color: '#FF9800',
    path: '/procurement/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/procurement/dashboard',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Material Request',
        icon: <Icon icon='fluent:call-add-16-regular' />,
        path: '/procurement/material-request',
        children: null,
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'All Masters',
        path: '/procurement/masters',
        icon: <Icon icon='fluent:settings-16-regular' />,
        children: [
          {
            name: 'Buildings',
            path: '/procurement/masters/building',
          },
          {
            name: 'Levels',
            path: '/procurement/masters/levels',
          },
          {
            name: 'Level Units',
            path: '/procurement/masters/levelUnits',
          },
          {
            name: 'Cost Centers',
            path: '/procurement/masters/costCenters',
          },
        ],
        menuPermissionGroups: [
          {
            menuPermissionGroupId: 1,
            menuId: 1,
            permissionGroupId: 2,
            status: 2,
            statusName: 'ACTIVE',
            createdDate: '2024-06-07',
            updatedDate: null,
            permissionGroup: {
              permissionGroupId: 2,
              permissionGroupCode: 'Default',
              permissionGroupName: 'Default',
              notes: 'default group',
              status: 2,
              statusName: 'ACTIVE',
              createdDate: '2024-06-07',
              updatedDate: null,
              permissions: [
                {
                  permissionId: 1,
                  permissionCode: 'View',
                  permissionName: 'View',
                  command: 'View',
                  notes: 'view',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
                {
                  permissionId: 2,
                  permissionCode: 'Create',
                  permissionName: 'Create',
                  command: 'Create',
                  notes: 'create',
                  status: 2,
                  statusName: 'ACTIVE',
                  createdDate: '2024-06-07',
                  updatedDate: null,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Item Management',
        icon: <Icon icon='fluent:box-search-16-regular' />,
        path: '/procurement/item-management',
        children: null,
      },
      {
        name: 'Purchase Order View',
        icon: <Icon icon='fluent:border-inside-16-regular' />,
        path: '/procurement/purchase-order-view',
        children: null,
      },
      {
        name: 'Request for Quote Management',
        icon: <Icon icon='fluent:chat-add-16-regular' />,
        path: '/procurement/request-for-quote-management',
        children: null,
      },
      {
        name: 'Goods Received Note',
        icon: <Icon icon='fluent:vehicle-truck-profile-16-regular' />,
        path: '/procurement/goods-received-note',
        children: null,
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/procurement/document-management',
        children: null,
      },
      // {
      //   name: "Vendor Management",
      //   icon: <Icon icon="icon-park-outline:every-user" />,
      //   path: "/procurement/vendor-management",
      //   children: null,
      // },
      // {
      //   name: "Transaction Approval Management",
      //   icon: <Icon icon="grommet-icons:transaction" />,
      //   path: "/procurement/transaction-approval-management",
      //   children: null,
      // },
    ],
  },
  {
    id: 8,
    name: 'Financial',
    icon: <Icon icon='material-symbols:finance-mode-rounded' />,
    color: '#FF9800',
    path: '/financial/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='material-symbols-light:finance-rounded' />,
        path: '/financial/dashboard',
        children: null,
      },
      {
        name: 'General Ledger Accounting',
        icon: <Icon icon='emojione-monotone:ledger' />,
        path: '/financial/general-ledger-accounting',
        children: null,
      },
      {
        name: 'Accounts Payable',
        icon: <Icon icon='game-icons:pay-money' />,
        path: '/financial/accounts-payable',
        children: null,
      },
      {
        name: 'Accounts Receivable',
        icon: <Icon icon='game-icons:receive-money' />,
        path: '/financial/accounts-receivable',
        children: null,
      },
      {
        name: 'Asset Accounting',
        icon: <Icon icon='f7:building-2' />,
        path: '/financial/asset-accounting',
        children: null,
      },
      {
        name: 'Bank Accounting',
        icon: <Icon icon='akar-icons:bank' />,
        path: '/financial/bank-accounting',
        children: null,
      },
      {
        name: 'Travel Management',
        icon: <Icon icon='material-symbols-light:travel' />,
        path: '/financial/travel-management',
        children: null,
      },
      {
        name: 'Legal Consolidation',
        icon: <Icon icon='hugeicons:legal-document-01' />,
        path: '/financial/legal-consolidation',
        children: null,
      },
      {
        name: 'General Ledger Closing Operations',
        icon: <Icon icon='token:ledger' />,
        path: '/financial/general-ledger-closing-operations',
        children: null,
      },
    ],
  },
  {
    id: 9,
    name: 'Budgeting',
    icon: <Icon icon='arcticons:budgetmylife' />,
    color: '#FF9800',
    path: '/budgeting/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/budgeting/dashboard',
        children: null,
      },
      {
        name: 'Cost Element Accounting',
        icon: <Icon icon='material-symbols-light:jump-to-element' />,
        path: '/budgeting/cost-element-accounting',
        children: null,
      },
      {
        name: 'Cost Center Accounting',
        icon: <Icon icon='fluent-mdl2:add-home' />,
        path: '/budgeting/cost-center-accounting',
        children: null,
      },
      {
        name: 'Internal Orders',
        icon: <Icon icon='icon-park-outline:internal-transmission' />,
        path: '/budgeting/internal-orders',
        children: null,
      },
      {
        name: 'Profit Center Accounting',
        icon: <Icon icon='carbon:center-circle' />,
        path: '/budgeting/profit-center-accounting',
        children: null,
      },
      {
        name: 'Profitability Analysis',
        icon: <Icon icon='game-icons:profit' />,
        path: '/budgeting/profitability-analysis',
        children: null,
      },
    ],
  },
  {
    id: 9,
    name: 'HSE',
    icon: <Icon icon='pajamas:status-health' />,
    color: '#FF9800',
    path: '/hse/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/hse/dashboard',
        children: null,
      },
      {
        name: 'Occupational Health',
        icon: <Icon icon='solar:health-outline' />,
        path: '/hse/occupational-health',
        children: null,
      },
      {
        name: 'Occupational Safety',
        icon: <Icon icon='uiw:safety' />,
        path: '/hse/occupational-safety',
        children: null,
      },
      {
        name: 'Environmental Management',
        icon: <Icon icon='fa6-solid:helmet-safety' />,
        path: '/hse/environmental-management',
        children: null,
      },
      {
        name: 'Dangerous Goods Management',
        icon: <Icon icon='maki:danger' />,
        path: '/hse/dangerous-goods-management',
        children: null,
      },
      {
        name: 'Industrial Hygiene',
        icon: <Icon icon='covid:personal-hygiene-clean-gel' />,
        path: '/hse/industrial-hygiene',
        children: null,
      },
      {
        name: 'Incident Management',
        icon: <Icon icon='carbon:traffic-flow-incident' />,
        path: '/hse/incident-management',
        children: null,
      },
      {
        name: 'Audit Management',
        icon: <Icon icon='icon-park-outline:audit' />,
        path: '/hse/audit-management',
        children: null,
      },
      {
        name: 'Waste Management',
        icon: <Icon icon='mingcute:wastebasket-line' />,
        path: '/financial/waste-management',
        children: null,
      },
      {
        name: 'Chemical Management',
        icon: <Icon icon='game-icons:nuclear-waste' />,
        path: '/financial/chemical-management',
        children: null,
      },
      {
        name: 'Sustainability Performance Management',
        icon: <Icon icon='carbon:sustainability' />,
        path: '/financial/sustainability-performance-management',
        children: null,
      },
      {
        name: 'Risk & Regulatory Compliance',
        icon: <Icon icon='streamline:asterisk-1-solid' />,
        path: '/financial/risk-regulatory-compliance',
        children: null,
      },
    ],
  },
];

export default Nav;
