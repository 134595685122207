import React from "react";
import { Button, Box } from "@mui/material";
import Icons from "../../utils/Icon";
import { useTranslation } from "react-i18next";

const HeaderSearchButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      sx={{
        textTransform: "none",
        borderRadius: "35px",
        padding: "5px 12px",
        color: "inherit",
        minWidth: "250px",
        textAlign: "left",
        fontSize: "inherit",
        cursor: "text",
        border: "none",
        bgcolor: "background.white",
        "&:hover": {},
      }}
      className="gap-3 sm:hidden"
      onClick={onClick}
    >
      <Box sx={{ fontSize: "18px", display: "flex", alignItems: "center" }}>
        {Icons.search}
      </Box>
      <p className="flex-1">{t("search") + "..."}</p>
      <Box
        sx={{
          fontSize: "13px",
          marginLeft: "5px",
          color: "inherit",
          opacity: 0.7,
        }}
      >
        ⌘K
      </Box>
    </Button>
  );
};

export default HeaderSearchButton;
