import { Icon } from "@iconify/react";

const Icons = {
  bell: <Icon icon="fluent:alert-24-regular" />,
  history: <Icon icon="fluent:history-24-regular" />,
  search: <Icon icon="fluent:search-16-regular" />,
  profile: <Icon icon="solar:user-circle-broken" />,
  settings: <Icon icon="solar:settings-outline" />,
  logout: <Icon icon="solar:logout-2-outline" />,
  moon: <Icon icon="fluent:weather-moon-16-regular" />,
  sun: <Icon icon="material-symbols:sunny-outline" />,
  powerOff: <Icon icon="solar:power-bold" />,
  menu: <Icon icon="mynaui:text-align-left" />,
  menuClose: <Icon icon="mynaui:text-align-right" />,
  arrow: <Icon icon="material-symbols:keyboard-arrow-down" />,
  arrowUp: <Icon icon="material-symbols:keyboard-arrow-up" />,
  hrms: <Icon icon="solar:accessibility-broken" />,
  apps: <Icon icon="fluent:tab-desktop-search-16-regular" />,
  language: <Icon icon="fluent:translate-auto-20-regular" />,
  english: <Icon icon="twemoji:flag-england" />,
  arabic: <Icon icon="twemoji:flag-united-arab-emirates" />,
  france: <Icon icon="twemoji:flag-france" />,
  mails: <Icon icon="lucide:mails" />,
  grid: <Icon icon="fluent:office-apps-28-regular" />,
  save: <Icon icon="mdi:content-save" />,
  edit: <Icon icon="mdi:pencil" />,
  delete: <Icon icon="mdi:delete" />,
  clear: <Icon icon="mdi:close-thick" />,
  view: <Icon icon="bi:eye" />,
};

export default Icons;
