import React, { useState } from "react";
import Header from "./Header";
import { Box } from "@mui/material/";
import Sidebar from "./Sidebar";
import Title from "./page/Title";
import PageSubmenu from "./page/PageSubmenu";

interface DashboardLayoutProps {
  title: string;
  children: React.ReactNode;
  actionButtons?: React.ReactNode;
  hasSubmenu?: boolean;
  menu?: any;
  parentMenu?: string;
  parentMenuUrl?: string;
  parentMenuHide?: boolean;
}
const DashboardLayout = (props: DashboardLayoutProps) => {
  const {
    title,
    children,
    actionButtons,
    hasSubmenu,
    menu,
    parentMenu,
    parentMenuUrl,
    parentMenuHide,
  } = props;

  const [menuCollapse, setMenuCollapse] = useState(false);

  const HandleMenuCollapse = () => {
    setMenuCollapse(!menuCollapse);
  };

  const topHeight = "55px";

  return (
    <>
      <Box className="flex flex-col w-full gap-2 h-screen overflow-hidden sm:h-auto">
        <Header
          HandleMenuCollapse={HandleMenuCollapse}
          collaspeMenuState={menuCollapse}
        />
        <Box className="w-full flex flex-1 gap-4 h-full" sx={{ minHeight: 0 }}>
          <Sidebar open={menuCollapse} />
          <Box className="h-full flex-1" sx={{ minWidth: 0 }}>
            <Box
              className="flex flex-col w-full h-full sm:rounded-none sm:h-auto"
              sx={{
                borderRadius: "15px 0 0 0",
                bgcolor: "background.overlay",
                overflow: "hidden",
                border: "1px solid",
                borderColor: "border.main",
                borderRight: "none",
                borderBottom: "none",
              }}
            >
              {title && (
                <>
                  <Box
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "border.main",
                    }}
                  >
                    <Title title={title}>{actionButtons}</Title>
                    {hasSubmenu && (
                      <PageSubmenu
                        menu={menu}
                        parentMenu={parentMenu}
                        parentMenuUrl={parentMenuUrl}
                        parentMenuHide={parentMenuHide}
                      />
                    )}
                  </Box>
                </>
              )}
              <Box
                className="p-4 w-full flex-1  h-full overflow-auto sm:h-auto"
                sx={{ minHeight: 0 }}
              >
                {children}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
