import React from 'react';
import { Button, Box } from '@mui/material';

const SideBarMenuButton = ({ name, icon, ...props }) => {
  return (
    <Button
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        px: '5px',
        py: 0.3,
        borderRadius: '8px',
        textAlign: 'left',
        fontWeight: 600,
        opacity: 0.8,
        color: 'inherit',
        '&:hover': {
          bgcolor: 'background.overlayTwo',
          opacity: 1,
        },
        '&.active': {
          background: 'linear-gradient(to right, #067E89, #f46b4500)',
          fontWeight: 600,
          boxShadow: '0 6px 12px rgb(140 152 164 / 8%)',
          color: 'text.white',
          opacity: 1,
        },
      }}
      title={name}
      {...props}
    >
      <Box
        className='icon flex justify-center items-center'
        sx={{
          minWidth: '30px',
          height: '30px',
          marginRight: '5px',
          '& svg': {
            fontSize: '20px',
          },
        }}
      >
        {icon}
      </Box>
      <Box sx={{ width: 'calc(100% - 35px)' }}>
        <Box className='w-full overflow-hidden text-ellipsis whitespace-nowrap'>
          {name}
        </Box>
      </Box>
    </Button>
  );
};

export default SideBarMenuButton;
