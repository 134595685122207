import { Box } from "@mui/material";
import React from "react";
import Breadcums from "../helpers/Breadcums";

const Title = ({ title, children }) => {
  return (
    <Box
      className="flex flex-row items-center justify-between p-2 px-4"
      sx={{ color: "text.main" }}
    >
      {/* flex */}
      <Box>
        <Box className="text-sm font-bold mb-1" sx={{ color: "text.dark" }}>
          {title}
        </Box>
        <Box>
          <Breadcums />
        </Box>
      </Box>
      {/* flex */}
      <Box className="flex flex-row items-center gap-3">{children}</Box>
    </Box>
  );
};

export default Title;
