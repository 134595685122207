import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import HeaderMenu from "./helpers/HeaderMenu";
import HeaderNotification from "./helpers/HeaderNotification";
import HeaderHistoryDropdown from "./helpers/HeaderHistoryDropdown";
import SearchModal from "./SearchModal";
import PropTypes, { string } from "prop-types";
import Icons from "../utils/Icon";
import HeaderSearchButton from "./helpers/HeaderSearchButton";
import HeaderAppDropdown from "./helpers/HeaderAppDropdown";
import { IconButtonWithTooltip } from "./small/Buttons";
import MenuPopper from "./MenuPopper";
import HeaderLanguage from "./helpers/HeaderLanguage";
import { useTheme } from "../ThemeContext.jsx";

const Header = (props) => {
  const { type, HandleMenuCollapse, collaspeMenuState } = props;
  const [showSearch, setShowSearch] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const { toggleTheme, mode } = useTheme();

  const handleOpen = () => setShowSearch(true);
  const handleClose = () => setShowSearch(false);

  const [activeMenuId, setactiveMenuId] = React.useState(0);

  const handleMenuOpen = (i) => {
    setShowMenu(!showMenu);
    setactiveMenuId(i);
  };

  return (
    <>
      <Box
        id="header_id"
        sx={{
          width: "100%",
          display: "flex",
          px: "8px",
          py: "5px",
          justifyContent: "space-between",
          alignItems: "center",
          // position: "fixed",
          // top: 0,
          // left: 0,
          zIndex: 11,
          backdropFilter: "blur(40px)",
        }}
        className="sm:flex-col"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box className="sm:hidden">
            <IconButtonWithTooltip
              name={!collaspeMenuState ? "Collapse" : "Expand"}
              icon={!collaspeMenuState ? Icons.menuClose : Icons.menu}
              onClick={() => HandleMenuCollapse()}
            />
          </Box>
          <Box
            component="img"
            src={
              mode === "dark"
                ? "/images/logo-white.png"
                : "/images/logo-black.png"
            }
            sx={{ maxWidth: "100%", height: "35px" }}
          />

          {type !== "ESS" && <HeaderAppDropdown />}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={6}>
          <HeaderSearchButton onClick={handleOpen} />
          {/* <LanguageSelector /> */}
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <IconButtonWithTooltip
              name="Menu"
              icon={Icons.grid}
              onClick={() => handleMenuOpen()}
            />
            <HeaderLanguage />

            <IconButtonWithTooltip
              name={mode === "dark" ? "Light Mode" : "Dark Mode"}
              icon={mode === "dark" ? Icons.sun : Icons.moon}
              onClick={toggleTheme}
            />
            <HeaderHistoryDropdown />
            <HeaderNotification />
          </Stack>
          <HeaderMenu />
        </Stack>
      </Box>
      <SearchModal openprop={showSearch} onCloseprop={handleClose} />
      <MenuPopper
        open={showMenu}
        handleOpen={handleMenuOpen}
        activeId={activeMenuId}
        handleClose={handleMenuOpen}
      />
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  type: string,
};

export default Header;
