import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaginationState {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
}

const initialState: PaginationState = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
});

export const { setPage, setPageSize, setTotalPages } = paginationSlice.actions;

export default paginationSlice.reducer;
