import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { FontSize } from "./FontSize";

const MainColors = {
  main: "#067E89",
  secondary: "#d48108",
  border: "#e3e7ed",
  yellow: "#fcfc03",
  bgLight: "#EAF0F9",
  textMain: "rgba(2, 6, 12, 0.69)",
  textDark: "rgba(2, 6, 12, 0.92)",
  textLight: "#6d6d6d",
  white: "#ffffff",
  mainLight: "#eaf4f5",
  lightsm: "#f2f7ff",
  glassBg: "rgba(255, 255, 255, 0.38)",
  blue: "#1d4ed8",
  blueLight: "#e9f6ff",
  mainLightBase: "#e0fcff",
  bgOverlay: "rgb(255 255 255 / 60%)",
  bgOverlayTwo: "rgb(185 199 221 / 31%)",
};

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: MainColors.main,
    },
    secondary: {
      main: MainColors.secondary,
    },
    error: {
      main: red.A400,
    },
    yellow: {
      main: MainColors.yellow,
    },
    border: {
      main: MainColors.border,
      glass: "rgba(255, 255, 255, 0.3)",
    },
    text: {
      main: MainColors.textMain,
      dark: MainColors.textDark,
      light: MainColors.textLight,
      white: MainColors.white,
    },
    background: {
      default: MainColors.white,
      light: MainColors.bgLight,
      white: MainColors.white,
      mainLight: MainColors.mainLight,
      mainLightBase: MainColors.mainLightBase,
      lightsm: MainColors.lightsm,
      glass: MainColors.glassBg,
      overlay: MainColors.bgOverlay,
      overlayTwo: MainColors.bgOverlayTwo,
      blueLight: MainColors.blueLight,
    },
    shadow: {
      main: "0 1px 10px rgba(0, 0, 0, 0.1)",
      glass: "0 4px 30px rgba(0, 0, 0, 0.1",
    },
    shape: {
      main: 3,
    },
  },
  typography: {
    fontFamily: ['"Inter"', "sans-serif"].join(","),
    allVariants: {
      color: MainColors.textMain,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "#fff url(/images/bg.png) no-repeat center fixed",
          fontSize: FontSize.body,
          backgroundSize: "cover",
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          fontSize: "inherit",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            fontWeight: "500",
            borderRadius: "5px",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            fontWeight: "500",
            borderRadius: "5px",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
        },
        {
          props: { variant: "text" },
          style: {
            fontWeight: "500",
            borderRadius: "5px",
            paddingLeft: "15px",
            paddingRight: "15px",
            color: MainColors.textMain,
          },
        },
      ],
    },

    MuiMenu: {
      styleOverrides: {
        paper: {},
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "5px",
          backgroundColor: MainColors.white,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
          "&:hover": {
            background: MainColors.bgLight,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
          color: MainColors.textDark,
          backgroundColor: MainColors.white,
          fontWeight: 600,
          boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
          padding: "10px",
        },
        arrow: {
          color: MainColors.white,
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: "filled" },

          style: {
            "& .MuiFormLabel-root": {
              fontSize: "inherit",
              transform: "translate(10px, 10px) scale(1)",
              //position: "relative",
              //marginBottom: "5px",
              "&.MuiInputLabel-shrink": {
                transform: "translate(7px, 4px) scale(0.8)",
                fontWeight: 400,
              },
              "& .MuiTypography-root": {
                fontSize: "inherit",
              },
            },

            "& .MuiFormHelperText-root.Mui-error": {
              margin: 0,
              fontSize: "8px",
            },

            "& .MuiInputBase-root": {
              border: "1px solid",
              borderColor: MainColors.border,
              borderRadius: "5px",
              fontWeight: 600,
              background: "inherit",
              fontSize: "inherit",
              "&:before, &:after": {
                display: "none",
              },
              "&:hover, &.Mui-focused": {
                background: "rgb(133 196 246 / 16%)",
              },

              "& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root":
                {
                  fontSize: "1.2rem",
                },

              "&.MuiAutocomplete-inputRoot": {
                paddingTop: "13px",
                paddingLeft: "7px",
                "& .MuiInputBase-input": {
                  padding: "5px 0px",
                },
              },

              "&.MuiInputBase-multiline": {
                //padding: "6px 10px 7px",
              },

              "& .MuiInputBase-input": {
                paddingTop: "18px",
                paddingBottom: "4px",
                paddingLeft: "7px",
                transition: "inherit",
                "&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus":
                  {
                    boxShadow: "0 0 0px 1000px #fff inset !important",
                  },

                "&.MuiInputBase-inputMultiline": {
                  padding: 0,
                },
              },
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiFormLabel-root": {
              fontSize: "inherit",
              transform: "translate(11px, 10px) scale(1)",
              "&.MuiInputLabel-shrink": {
                transform: "translate(11px, -6px) scale(0.8)",
              },
            },
            "& .MuiInputLabel-shrink.MuiFormLabel-root": {
              marginTop: "0px",
            },
            "& .MuiInputBase-root": {
              background: MainColors.white,
              color: MainColors.textDark,
              fontSize: "inherit",
              borderRadius: "4px",
              fontWeight: 600,
              "&:hover, &.Mui-focused": {
                backgroundColor: MainColors.white,
              },

              "& .MuiInputBase-input": {
                padding: "10px 11px",
                "&.MuiInputBase-inputMultiline": {
                  padding: 0,
                },
              },
            },
          },
        },
      ],
    },

    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
          color: "inherit",
          "& .MuiSvgIcon-root": {
            color: "inherit",
            opacity: "0.5",
            "&:hover": {
              opacity: "1",
              color: "inherit",
            },
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            fontSize: "inherit",
            fontWeight: 700,
            textTransform: "inherit",
            flexDirection: "row",
            justifyContent: "flex-start",
            borderRadius: "0px",
            minHeight: "48px",
            // padding: "5px 10px",
            color: MainColors.textMain,
            // marginBottom: "5px",
            "& svg": {
              marginBottom: 0,
              marginRight: "10px",
              fontSize: "20px",
            },
          },
          "& .MuiTabPanel-root": {
            padding: 0,
          },
          "& .Mui-selected": {
            // background: MainColors.main,
            // color: `${MainColors.white}!important`,
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: {
          fontSize: "inherit",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
        },
        paper: {
          fontSize: "inherit",
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          //background: "rgb(255 255 255 / 40%)",
        },
        invisible: {
          background: "inherit",
          backdropFilter: "blur(0px)",
        },
      },
    },

  },
});

export default theme;
