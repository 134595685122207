// src/redux/reducers/projectsReducer.ts

import { createReducer } from '@reduxjs/toolkit';
import {
  fetchProjectsStart,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  selectProject,
  clearSelectedProject,
  deleteProject
} from '../actions/projectsActions.ts';

interface ProjectState {
  projects: any[];
  selectedProject: any | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ProjectState = {
  projects: [],
  selectedProject: null,
  isLoading: false,
  error: null,
};

const projectsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProjectsStart, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchProjectsSuccess, (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchProjectsFailure, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    })
    .addCase(selectProject, (state, action) => {
      state.selectedProject = action.payload;
    })
    .addCase(clearSelectedProject, (state) => {
      state.selectedProject = null;
    });
});

export default projectsReducer;
