import * as Yup from 'yup';

export const supplierValidationSchema = Yup.object().shape({
  officialSupplierName: Yup.string().required(
    'Official Supplier Name is required'
  ),

  address: Yup.string().required('Address is required'),

  headOfficeAddress: Yup.string().required('Head Office Address is required'),

  taxNumber: Yup.string().required('Tax Number is required'),
  taxPercentage: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Tax Percentage is required')
    .min(0, 'Tax Percentage cannot be less than 0')
    .max(100, 'Tax Percentage cannot exceed 100'),

  tradeLicenseNumber: Yup.string().required('Trade License Number is required'),

  agreement: Yup.boolean()
    .nullable() // Allows null values
    .required('Agreement is required'),

  debitORCredit: Yup.boolean().required('Debit or Credit option is required'),

  creditLimit: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Credit Limit is required')
    .min(0, 'Credit Limit cannot be negative'),

  supplierTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier Type is required')
    .positive('Supplier Type must be a positive number')
    .integer('Supplier Type must be an integer'),

  regionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Region is required')
    .positive('Region must be a positive number')
    .integer('Region must be an integer'),

  countryId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Country is required')
    .positive('Country must be a positive number')
    .integer('Country must be an integer'),
});

export const wpsSchema = Yup.object().shape({
  basicSalaryPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Basic Salary Percentage is required')
    .min(0, 'Basic Salary Percentage cannot be less than 0')
    .max(100, 'Basic Salary Percentage cannot exceed 100'),
  housingAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Housing Allowance Percentage is required')
    .min(0, 'Housing Allowance Percentage cannot be less than 0')
    .max(100, 'Housing Allowance Percentage cannot exceed 100'),
  additionalAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Additional Allowance Percentage is required')
    .min(0, 'Additional Allowance Percentage cannot be less than 0')
    .max(100, 'Additional Allowance Percentage cannot exceed 100'),
  incentivePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Incentives Percentage is required')
    .min(0, 'Incentives Percentage cannot be less than 0')
    .max(100, 'Incentives Percentage cannot exceed 100'),
  otherAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Other Allowance Percentage is required')
    .min(0, 'Other Allowance Percentage cannot be less than 0')
    .max(100, 'Other Allowance Percentage cannot exceed 100'),
});

export const supplierItemSchema = Yup.object().shape({
  supplierId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier is required')
    .positive('Supplier must be a positive number')
    .integer('Supplier must be an integer'),

  itemId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item is required')
    .positive('Item must be a positive number')
    .integer('Item must be an integer'),

  description: Yup.string().required('Description is required'),
  notes: Yup.string().nullable(),

  sku: Yup.string().required('SKU is required'),

  unitPrice: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Unit Price is required')
    .min(0, 'Unit Price cannot be negative'),

  quantity: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Quantity is required')
    .positive('Quantity must be greater than 0')
    .integer('Quantity must be a whole number'),

  taxCodeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tax Code is required')
    .positive('Tax Code must be a positive number')
    .integer('Tax Code must be an integer'),

  discountPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Discount Percent is required')
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),

  totalPrice: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Total Price is required')
    .min(0, 'Total Price cannot be negative'),
});

export const EstimationMEPSchema = Yup.object().shape({
  projectId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Project is required')
    .positive('Project must be a positive number')
    .integer('Project must be an integer'),

  clientId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Client is required')
    .positive('Client must be a positive number')
    .integer('Client must be an integer'),

  tenderId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tender is required')
    .positive('Tender must be a positive number')
    .integer('Tender must be an integer'),

  buildingId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Building is required')
    .positive('Building must be a positive number')
    .integer('Building must be an integer'),

  dateOfEnquiry: Yup.date().required('Date of Enquiry is required'),
  contractPeriod: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract Period is required')
    .positive('Contract Period must be a positive number')
    .integer('Contract Period must be an integer'),

  siteVisitDoneBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Site Visit Done By is required')
    .positive('Site Visit Done By must be a positive number')
    .integer('Site Visit Done By must be an integer'),

  existContract: Yup.string().required('Exist Contract is required'),

  preparedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Prepared By is required')
    .positive('Prepared By must be a positive number')
    .integer('Prepared By must be an integer'),

  discountPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
  otherPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Other Percent is required')
    .min(0, 'Other Percent cannot be negative')
    .max(100, 'Other Percent cannot exceed 100'),
});
export const leaveRequestSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  leaveTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Leave Type is required'),
  fromDate: Yup.date().required('From Date is required'),
  toDate: Yup.date().required('To Date is required'),
  reason: Yup.string().required('Reason is required'),
  leaveRequestStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Leave Request Status is required'),
  leaveDay: Yup.number().required('Leave Day is required'),
  noOfDays: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('No of Days is required')
    .min(1),
  remarks: Yup.string().required('Remarks is required'),
  lastAnnualReturnLeaveDate: Yup.date().required('`'),
  currentDate: Yup.date().required('Current Date is required'),
  sponsership: Yup.string().required('Sponsership is required'),

  dateOfTravel: Yup.date().required('Date of Travel is required'),
  homeCountryContactNumber: Yup.string()
    .required('Home Country Contact Number is required')
    .min(8, 'Home Country Contact Number must be at least 8 characters')
    .max(12, 'Home Country Contact Number must be at least 12 characters'),
  uaeNumber: Yup.string().required('UAE Contact Number is required'),
});

export const leaveJoinSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  currentDate: Yup.date().required('Current Date is required'),
  dateOfArrival: Yup.date().required('Date of Arrival is required'),
  dateOfResume: Yup.date().required('Date of Resume is required'),
  additionalDays: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Additional Days is required')
    .positive('Additional Days must be a positive number'),
  delayDays: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Delay Days is required')
    .min(1)
    .positive('Delay Days must be a positive number'),
  remarks: Yup.string().required('Remarks is required'),
});

export const passportRequestSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  appliedOn: Yup.date().required('Applied On is required'),
  passportRequestTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Passport Request Type is required'),
  issuedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Issued By is required'),

  remarks: Yup.string().required('Remarks is required'),
});

export const employeeCancellationSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  cancellationTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cancellation Type is required'),
  cancellationDate: Yup.date().required('Cancellation Date is required'),
  reason: Yup.string().required('Reason is required'),
  lastDate: Yup.date().required('Last Date is required'),
  departmentClearanceDocumentPath: Yup.string().required(
    'Document Path is required'
  ),
  contractBreach: Yup.string().required('Contract Breach is required'),
  exitday: Yup.date().required('Exit Day is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const certificateSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  certificationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Certification is required'),
  certificateTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Certificate Type is required'),
  professionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Profession is required'),
  projectId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Project is required'),
  contractId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
  startDate: Yup.date().required('Start Date is required'),
  expiryDate: Yup.date().required('Expiry Date is required'),
  lastRenewDate: Yup.date().required('Last Renew Date is required'),
  passCancelledDate: Yup.date().required('Pass Cancelled Date is required'),
  remarks: Yup.string().required('Remarks is required'),
  loaInformation: Yup.string().required('LOA Information is required'),
  cicpaNumber: Yup.string().required('CICPA Number is required'),
});

export const jobCertificationSchema = Yup.object().shape({
  requisitionTitle: Yup.string().required('Requisition Title is required'),
  requisitionDescription: Yup.string().required(
    'Requisition Description is required'
  ),
  createJobRequisitionDetails: Yup.array()
    .of(
      Yup.object().shape({
        requestedDepartmentId: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .positive('Job Requisition Details must be a positive number')
          .required('Job Requisition Details is required'),
        experienceLevel: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Experience Level is required'),
        projectId: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Project is required'),
        closedDate: Yup.date().required('Closed Date is required'),
        noOfPosition: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('No of Position is required'),
        fromBudget: Yup.number().transform((value, originalValue) =>
          originalValue === '' ? null : value
        ),
        toBudget: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('To Budget is required'),
        requestedBy: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Requested By is required'),
        skill: Yup.string().required('Skill is required'),
        jobRequisitionStatusId: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Job Requisition Status is required'),
      })
    )
    .required('Job Requisition Details is required'),
});

export const eventsSchema = Yup.object().shape({
  eventName: Yup.string().required('Event Name is required'),
  eventTopic: Yup.string().required('Event Topic is required'),
  eventDescription: Yup.string().required('Event Description is required'),
  eventDate: Yup.date().required('Event Date is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  organizedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Organized By is required'),
  attendeessCount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Attendees Count is required'),
  eventStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Event Status is required'),
  approvalStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Approval Status is required'),
});

export const grnSchema = Yup.object().shape({
  materialRequisitionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Material Requisition is required'),
  localPurchaseOrderId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Local Purchase Order is required'),
  supplierId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier is required'),
  deliveryChallanNumber: Yup.string().required(
    'Delivery Challan Number is required'
  ),
  deliveryDate: Yup.date().required('Delivery Date is required'),
  grnDate: Yup.date().required('GRN Date is required'),
  fileId: Yup.number().transform((value, originalValue) =>
    originalValue === '' ? null : value
  ),
  remarks: Yup.string().required('Remarks is required'),
});

export const purchaseFormSchema = Yup.object().shape({
  documentId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Material Requisition is required'),
  lpoDate: Yup.date().required('LPO Date is required'),
  vendorAccount: Yup.string().required('Vendor Account is required'),
  currencyId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Currency is required'),
  exchangeRate: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Exchange Rate is required'),
  companyId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Company is required'),
  projectId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Project is required'),
  contractId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
  departmentId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Department is required'),
  costCenterId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cost Center is required'),
  jurisdiction: Yup.string().required('Jurisdiction is required'),
  inCharge: Yup.string().required('In Charge is required'),
  materialRequisitionTypeID: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Material Requisition Type is required'),
  requestor: Yup.string().required('Requestor is required'),
  deliveryLocation: Yup.string().required('Delivery Location is required'),
  supplierSPOCId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier SPOC is required'),
  materialRequisitionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Material Requisition is required'),
  salesOrderId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Sales Order is required'),
  deliveryTerms: Yup.string().required('Delivery Terms is required'),

  paymentTermID: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Payment Term is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const itemsSchema = Yup.object().shape({
  itemShortCode: Yup.string().required('Item Short Code is required'),
  itemName: Yup.string().required('Item Name is required'),
  description: Yup.string().required('Description is required'),
  technicalSpecifications: Yup.string().required(
    'Technical Specifications is required'
  ),
  model: Yup.string().required('Model is required'),
  unitOfMeasurementId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Unit of Measurement is required'),
  taxCodeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tax Code is required'),
  itemTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item Type is required'),
  costCenterId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cost Center is required'),
  valuationMethodId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Valuation Method is required'),
  itemGroupId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item Group is required'),
  brandId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Brand is required'),

  groupTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Group Type is required'),
});

export const CompanyInsurancePolicySchema = Yup.object().shape({
  companyInsurancePolicyId: Yup.number()
    .positive('Company Insurance Policy ID must be a positive number')
    .required('Company Insurance Policy ID is required'),
  companyInsurancePolicyEndUserId: Yup.number()
    .positive('End User ID must be a positive number')
    .required('End User ID is required'),
  companyInsurancePolicyTypeOfProductId: Yup.number()
    .positive('Type of Product ID must be a positive number')
    .required('Type of Product ID is required'),
  companyInsurancePolicyTypeId: Yup.number()
    .positive('Policy Type ID must be a positive number')
    .required('Policy Type ID is required'),
  companyInsurancePolicyInsurerId: Yup.number()
    .positive('Insurer ID must be a positive number')
    .required('Insurer ID is required'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must be at least 5 characters long'),
  policyDetail: Yup.string().required('Policy Detail is required'),
  insuredSum: Yup.number()
    .typeError('Insured Sum must be a number')
    .positive('Insured Sum must be a positive number')
    .required('Insured Sum is required'),
  remarks: Yup.string().required('Remarks is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  dateOfPriorNotification: Yup.date().required(
    'Date of Prior Notification is required'
  ),
});

export const CompanyInsurancePolicyComparisonSchema = Yup.object().shape({
  companyInsurancePolicyId: Yup.number()
    .positive('Company Insurance Policy ID must be a positive number')
    .required('Company Insurance Policy ID is required'),
  companyInsurancePolicyEndUserId: Yup.number()
    .positive('End User ID must be a positive number')
    .required('End User ID is required'),
  companyInsurancePolicyTypeOfProductId: Yup.number()
    .positive('Type of Product ID must be a positive number')
    .required('Type of Product ID is required'),
  companyInsurancePolicyTypeId: Yup.number()
    .positive('Policy Type ID must be a positive number')
    .required('Policy Type ID is required'),
  companyInsurancePolicyInsurerId: Yup.number()
    .positive('Insurer ID must be a positive number')
    .required('Insurer ID is required'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must be at least 5 characters long'),
  policyDetail: Yup.string().required('Policy Detail is required'),
  insuredSum: Yup.number()
    .typeError('Insured Sum must be a number')
    .positive('Insured Sum must be a positive number')
    .required('Insured Sum is required'),
  remarks: Yup.string().required('Remarks is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  dateOfPriorNotification: Yup.date().required(
    'Date of Prior Notification is required'
  ),
});
export const MIPPremiunCalculatorSchema = Yup.object().shape({
  companyMedicalInsuranceAgeBandId: Yup.number()
    .positive('Age Band ID must be a positive number')
    .required('Age Band ID is required'),

  companyMedicalInsuranceDependencyId: Yup.number()
    .positive('Dependency ID must be a positive number')
    .required('Dependency ID is required'),

  medicalInsurancePolicyCategories: Yup.array().of(
    Yup.object().shape({
      gradeId: Yup.number()
        .positive('Grade ID must be a positive number')
        .required('Grade ID is required'),
    })
  ),
});

export const ProjectMainCostSchema = Yup.object().shape({
  projectDivisionId: Yup.number()
    .positive('Project Division ID must be a positive number')
    .required('Project Division ID is required'),
  projectTypeId: Yup.number()
    .positive('Project Type ID must be a positive number')
    .required('Project Type ID is required'),
  boqId: Yup.number()
    .positive('BOQ ID must be a positive number')
    .required('BOQ ID is required'),
  packageId: Yup.number()
    .positive('Package ID must be a positive number')
    .required('Package ID is required'),
  subHeadId: Yup.number()
    .positive('SubHead ID must be a positive number')
    .required('SubHead ID is required'),
  lineItemId: Yup.number()
    .positive('Line Item ID must be a positive number')
    .required('Line Item ID is required'),
  requestedDate: Yup.date()
    .nullable()
    .transform((value) => (value === null ? undefined : value))
    .typeError('Requested Date must be a valid date')
    .required('Requested Date is required'),
  originalCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Original Cost must be at least 0')
    .required('Original Cost is required'),
  budgetCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget Cost must be at least 0')
    .required('Budget Cost Amount is required'),
  budgetCOD: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget COD must be at least 0')
    .required('Budget COD is required'),
  budgetCODPercent: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget COD Percent must be at least 0')
    .max(100, 'Budget COD Percent cannot be more than 100')
    .required('Budget COD Percent is required'),
  actualCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Actual Cost must be at least 0')
    .required('Actual Cost is required'),
});

export const AccommodationSchema = Yup.object().shape({
  locationId: Yup.number()
    .min(1, 'Location ID must be greater than 0')
    .required('Location ID is required'),
  premiseTypeId: Yup.number()
    .min(1, 'Type ID must be greater than 0')
    .required('Type ID is required'),
  premisePurposeId: Yup.number()
    .min(1, 'Purpose ID must be greater than 0')
    .required('Purpose ID is required'),
  duration: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Duration must be greater than 0')
    .required('Duration is required'),
  noOfRoom: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Number of rooms must be greater than 0')
    .required('Number of rooms is required'),
  noOfBedSpace: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Number of bed spaces must be greater than 0')
    .required('Number of bed spaces is required'),
  startDate: Yup.date().required('Start date is required').nullable(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'End date cannot be before start date')
    .required('End date is required')
    .nullable(),
  beneficiaryDetails: Yup.string().required('Beneficiary details are required'),
  contactInformation: Yup.string().required('Contact information is required'),
  contractNo: Yup.string().required('Contract number is required'),
  size: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Size must be greater than 0')
    .required('Size is required'),
  securityDeposit: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Security deposit cannot be negative')
    .required('Security deposit is required'),
  rentalCharges: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Rental charges cannot be negative')
    .required('Rental charges are required'),
  laundryCharges: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Laundry charges cannot be negative')
    .required('Laundry charges are required'),
  chargesMonth: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Charges month must be greater than 0')
    .required('Charges month is required'),
  negotiation: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation must be greater than or equal to 0')
    .required('Negotiation is required'),
  remarks: Yup.string().nullable(),
});

export const BudgetCostTrackingValidationSchema = Yup.object().shape({
  boqId: Yup.number()
    .positive('BOQ ID must be a positive number')
    .required('BOQ ID is required'),
  packageId: Yup.number()
    .positive('Package ID must be a positive number')
    .required('Package ID is required'),
  subHeadId: Yup.number()
    .positive('SubHead ID must be a positive number')
    .required('SubHead ID is required'),
  lineItemId: Yup.number()
    .positive('Line Item ID must be a positive number')
    .required('Line Item ID is required'),
  materialAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Material Amount must be at least 0')
    .required('Material Amount is required'),
  manPowerAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Manpower Amount must be at least 0')
    .required('Manpower Amount is required'),
  machineryAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Machinery Amount must be at least 0')
    .required('Machinery Amount is required'),
});

export const ManpowerValidationSchema = Yup.object().shape({
  boqId: Yup.number()
    .positive('BOQ ID must be a positive number')
    .required('BOQ ID is required'),
  packageId: Yup.number()
    .positive('Package ID must be a positive number')
    .required('Package ID is required'),
  subHeadId: Yup.number()
    .positive('SubHead ID must be a positive number')
    .required('SubHead ID is required'),
  lineItemId: Yup.number()
    .positive('Line Item ID must be a positive number')
    .required('Line Item ID is required'),
  description: Yup.string().required('Description is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const PettyCashValidationSchema = Yup.object().shape({
  pettyCashCategoryId: Yup.number()
    .required('Petty Cash Category ID is required')
    .min(1, 'Petty Cash Category ID must be greater than 0'),

  expenseTypeId: Yup.number()
    .required('Expense Type ID is required')
    .min(1, 'Expense Type ID must be greater than 0'),

  refNumber: Yup.string().required('Reference Number is required'),
  pettyCashNumber: Yup.string().required('Petty Cash Number is required'),

  amount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Amount is required')
    .min(1, 'Amount must be greater than 0'),

  remarks: Yup.string().required('Remarks are required'),
});

export const InjuriesValidationSchema = Yup.object().shape({
  employeeId: Yup.number()
    .required('Employee ID is required')
    .min(1, 'Employee ID must be greater than 0'),
    companyId: Yup.number()
    .required('Company ID is required')
    .min(1, 'Company ID must be greater than 0'),
    positionId: Yup.number()
    .required('Position ID is required')
    .min(1, 'Position ID must be greater than 0'),
    description: Yup.string().required('description are required'),
});
