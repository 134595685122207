import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "../../../components/DashboardLayout.tsx";
import PageHeader from "../../../components/PageHeader";
import DataTable from "../../../components/DataTable.tsx";
import GlassCard from "../../../components/small/GlassCard.tsx";
import TableActionButtons from "../../../components/_form/TableActionButtons";
import { ViewField, StatusBadge } from "../../../components/_form/FormElements";
import { Icon } from "@iconify/react";
import { Box, Grid } from "@mui/material";
import { CreateButton } from "../../../components/small/Buttons";
import TableSearch from "../../../components/_form/TableSearch.jsx";
import MuiDialogOne from "../../../components/_mui/MuiDialogOne";
import { Nav } from "../../../utils";
import {
  CancellationType,
  Contract,
} from "../../../interfaces/EmployeeMasterInterfaces";
import Crud_Service from "../../../apis/CrudService";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CommonLoader from "../../../components/page/CommonLoader";
import useLoader from "../../../components/helpers/UseLoader.tsx";
import ActionButtons from "../../../components/_form/ActionButtons.tsx";
import useTableLogic from "../../../components/helpers/UseTableLogic.tsx";
import FmTextField from "../../../components/_mui/FmTextField.tsx";
import FmAutoComplete from "../../../components/_mui/FmAutoComplete.tsx";
import useCommonFetchApi from "../../../components/helpers/useCommonFetchApi.tsx";
import { statusOptions } from "../../../utils/CommonVariables.tsx";

const SuppliersSpoc = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState();
  const [loading, setLoader] = useState(false);
  const { handleSubmit, setValue, control, reset } =
    useForm<CancellationType>();
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic("supplierspocs");
  const statusOptions = [
    { statusId: 2, statusName: "Active" },
    { statusId: 1, statusName: "Inactive" },
  ];
  const [isFormValid, setIsFormValid] = useState(false);

  const watchedFields = useWatch({
    control,
    name: [
      "supplierSPOCName",
      "email",
      "phoneNumber",
      "role",
      "escalationLevel",
    ],
  });

  const validateForm = useCallback(() => {
    const isValid = watchedFields.every(
      (field) => field !== undefined && field !== null && field !== ""
    );
    setIsFormValid(isValid);
  }, [watchedFields]);

  useEffect(() => {
    validateForm();
  }, [watchedFields, validateForm]);

  useEffect(() => {
    document.title = `HRMS-Companies`;
  }, []);

  //get All Apis Common
  const { data: suppliers } = useCommonFetchApi("suppliers");

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleSubmitSpoc: SubmitHandler<Contract> = async (values) => {
    startCreateLoading();
    await crud.create("supplierspocs", values, (err, res) => {
      if (res?.status === 201) {
        setOpenCreate(false);
        stopCreateLoading();
        reset();
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
        console.error(t("something_Went_Wrong!"));
      }
    });
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle("supplierspocs", view?.supplierSPOCId, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setLoader(false);
      } else {
        console.error(t("something_Went_Wrong!"));
      }
    });
  };

  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle("supplierspocs", view?.supplierSPOCId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoader(false);
      } else {
        console.error(t("something_Went_Wrong!"));
        setLoader(false);
      }
    });
  };

  const handleEditSpoc = async (values) => {
    startEditLoading();
    await crud.update(
      "supplierspocs",
      values?.supplierSPOCId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          setEditModal(false);
          stopEditLoading();
          fetchData();
        } else {
          stopEditLoading();
          setEditModal(true);
          console.error(t("something_Went_Wrong!"));
        }
      }
    );
  };

  const handleDeleteClick = async (view) => {
    await crud.remove("supplierspocs", view?.supplierSPOCId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
      } else {
        console.error(t("something_Went_Wrong!"));
      }
    });
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const ActionData = [
    {
      name: t("view"),
      icon: <Icon icon="solar:eye-linear" />,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: t("edit"),
      icon: <Icon icon="solar:pen-new-round-linear" />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t("delete"),
      icon: <Icon icon="solar:trash-bin-2-linear" />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: "action",
      headerName: "",
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: "supplierSPOCCode",
      headerName: "Spoc Code",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "supplierSPOCName",
      headerName: "Spoc Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Spoc Email",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Spoc Contact Number",
      flex: 1,
    },
    {
      field: "escalationLevel",
      headerName: "Escalation Level",
      flex: 1,
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      flex: 1,
    },

    {
      field: "status",
      headerName: t("status"),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? "Active" : "Inactive"}
            type={params.value === 2 ? "green" : "red"}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout title="Companies">
      <GlassCard className="h-full-css">
        <Box className="h-full-css">
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && "bg-red-50"}`
            }
            sortingMode="server"
            paginationMode="server"
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className="p-2 w-full flex justify-between items-center"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "border.main",
                  }}
                >
                  <Box sx={{ maxWidth: "250px" }}>
                    <TableSearch
                      placeholder="Search"
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton name="New" onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create  */}

      <MuiDialogOne
        title={t("create")}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitSpoc)}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <FmTextField
                name="supplierSPOCName"
                control={control}
                label="Spoc Name"
                rules={{
                  required: "Spoc Name is required",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name="name"
                control={control}
                label="Name"
                rules={{
                  required: "Name is required",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name="email"
                control={control}
                label="Spoc Email"
                type="email"
                rules={{
                  required: "Spoc Email is required",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name="phoneNumber"
                control={control}
                label="Spoc Contact Number"
                type="number"
                rules={{
                  required: "Contact Number is required",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name="role"
                control={control}
                label="Role"
                rules={{
                  required: "Role is required",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name="escalationLevel"
                control={control}
                label="Escalation Level"
                rules={{
                  required: "Escalation Level is required",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name="supplierId"
                control={control}
                options={suppliers}
                label="Supplier"
                displayField="officialSupplierName"
                optionFields={["officialSupplierName"]}
                valueKey="supplierId"
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitSpoc)}
                onCancel={handleCreateClose}
                submitLoading={createLoading}
                cancelLoading={false}
                errorFields={!isFormValid}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* create */}

      {/* view */}
      <MuiDialogOne
        title="View Details"
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField label="Supplier Name" title={viewData?.supplierName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label="Spoc Code" title={viewData?.supplierSPOCCode} />
            </Grid>
            <Grid item md={6}>
              <ViewField label="Spoc Name" title={viewData?.supplierSPOCName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label="Spoc Email" title={viewData?.email} />
            </Grid>
            <Grid item md={6}>
              <ViewField label="Name" title={viewData?.name} />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label="Spoc Contact Number"
                title={viewData?.phoneNumber}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label="Escalation Level"
                title={viewData?.escalationLevel}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label="Role" title={viewData?.role} />
            </Grid>

            <Grid item md={6}>
              <ViewField label={t("status")} title={viewData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/* edit */}

      <MuiDialogOne
        title={t("update")}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditSpoc)}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FmTextField
                  name="supplierSPOCName"
                  control={control}
                  label="Spoc Name"
                  rules={{
                    required: "Spoc Name is required",
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name="name"
                  control={control}
                  label="Name"
                  rules={{
                    required: "Name is required",
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name="email"
                  control={control}
                  label="Spoc Email"
                  type="email"
                  rules={{
                    required: "Spoc Email is required",
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name="phoneNumber"
                  control={control}
                  label="Spoc Contact Number"
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField name="role" control={control} label="Role" />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name="supplierId"
                  control={control}
                  options={suppliers}
                  label="suppliers"
                  displayField="officialSupplierName"
                  optionFields={["officialSupplierName"]}
                  valueKey="supplierId"
                />
              </Grid>

              <Grid item md={6}>
                <FmAutoComplete
                  name="status"
                  control={control}
                  options={statusOptions}
                  label="Status"
                  displayField="statusName"
                  optionFields={["statusName"]}
                  valueKey="statusId"
                />
              </Grid>

              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditSpoc)}
                  onCancel={handleCreateClose}
                  submitLoading={createLoading}
                  cancelLoading={false}
                  errorFields={!isFormValid}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default SuppliersSpoc;
