// src/redux/sagas/projectsSaga.ts

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchProjectsStart,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  selectProject,
  deleteProject,
} from '../actions/projectsActions.ts';
import axios from 'axios';

const token = localStorage.getItem('token');
const rootUrl = process.env.REACT_APP_BASE;

// Helper function to set up headers with the token
const axiosConfig = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

function* fetchProjects() {
  try {
    const response = yield call(axios.get, `${rootUrl}/projects`, axiosConfig);
    if (response.status === 200) {
      yield put(fetchProjectsSuccess(response.data));
    } else {
      yield put(fetchProjectsFailure('Failed to fetch projects'));
    }
  } catch (error) {
    yield put(fetchProjectsFailure(error.message));
  }
}

function* fetchProjectDetails(action: ReturnType<typeof selectProject>) {
  try {
    const response = yield call(
      axios.get,
      `${rootUrl}/projects/${action.payload.projectId}`,
      axiosConfig
    );
    if (response.status === 200) {
      yield put(selectProject(response.data));
    } else {
      yield put(fetchProjectsFailure('Failed to fetch project details'));
    }
  } catch (error) {
    yield put(fetchProjectsFailure(error.message));
  }
}

function* deleteProjectSaga(action: ReturnType<typeof deleteProject>) {
  try {
    const response = yield call(
      axios.delete,
      `${rootUrl}/projects/${action.payload.projectId}`,
      axiosConfig
    );
    if (response.status === 204) {
      yield put(fetchProjectsStart());
    } else {
      yield put(fetchProjectsFailure('Failed to delete project'));
    }
  } catch (error) {
    yield put(fetchProjectsFailure(error.message));
  }
}

function* projectsSaga() {
  yield takeLatest(fetchProjectsStart.type, fetchProjects);
  yield takeLatest(selectProject.type, fetchProjectDetails);
  yield takeLatest(deleteProject.type, deleteProjectSaga);
}

export default projectsSaga;
