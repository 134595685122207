// src/redux/actions/projectsActions.ts

import { createAction } from '@reduxjs/toolkit';

export const fetchProjectsStart = createAction('FETCH_PROJECTS_START');
export const fetchProjectsSuccess = createAction<any[]>('FETCH_PROJECTS_SUCCESS');
export const fetchProjectsFailure = createAction<string>('FETCH_PROJECTS_FAILURE');
export const selectProject = createAction<any>('SELECT_PROJECT');
export const clearSelectedProject = createAction('CLEAR_SELECTED_PROJECT');
export const deleteProject = createAction<{ projectId: string }>('DELETE_PROJECT');
