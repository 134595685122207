import React from "react";
import {
  Drawer,
  DialogTitle,
  Box,
  IconButton,
  TextField,
  Stack,
  Dialog,
  InputAdornment,
  DialogContent,
} from "@mui/material";
import { Icon } from "@iconify/react";
import MenuRecentPages from "./MenuRecentPages";

const DrawerWithSearch = ({
  open,
  onClose,
  searchPlaceholder,
  searchValue,
  searchHandleChange,
  disableRestoreFocus,
  headerBottom,
  children,
}) => {
  const [historyShow, setHistoryShow] = React.useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableRestoreFocus={disableRestoreFocus}
      PaperProps={{
        sx: {
          width: "100%",
          color: "text.main",
          minHeight: "calc(100% - 64px)",
          maxWidth: "650px",
          overflow: "hidden",
          borderRadius: "8px",
          bgcolor: "background.white",
        },
      }}
    >
      <DialogTitle sx={{ fontSize: "inherit", padding: 0 }}>
        {/* header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "text.main",
          }}
          className="p-3"
        >
          <Stack
            spacing={1}
            direction="row"
            className="w-full"
            justifyContent="space-between"
          >
            <TextField
              placeholder={searchPlaceholder}
              id="outlined-size-small"
              size="small"
              value={searchValue}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      fontSize: "22px",
                      color: "inherit",
                      opacity: 0.5,
                    }}
                  >
                    <Icon icon="material-symbols:search" />
                  </InputAdornment>
                ),
                autoFocus: true,
              }}
              onChange={searchHandleChange}
              autoFocus={true}
              sx={{
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  borderRadius: "10px",
                  border: "1px solid",
                  borderColor: "border.main",
                  bgcolor: "background.white",
                  color: "text.main",
                  fontWeight: 600,
                  "& .MuiInputBase-inputAdornedStart": {
                    paddingLeft: 0,
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  display: "none",
                },
              }}
            />
            <Box className="flex gap-3 ">
              <IconButton
                onClick={() => setHistoryShow(!historyShow)}
                sx={{ color: "inherit", "& svg": { fontSize: "22px" } }}
                className="opacity-60"
              >
                <Icon
                  icon={
                    historyShow ? "bi:menu-down" : "material-symbols:history"
                  }
                />
              </IconButton>
              <IconButton
                onClick={onClose}
                sx={{ color: "inherit", "& svg": { fontSize: "22px" } }}
                className="opacity-60"
              >
                <Icon icon="material-symbols:close" />
              </IconButton>
            </Box>
          </Stack>
        </Box>

        {!historyShow && headerBottom}

        {/* header */}
      </DialogTitle>

      <DialogContent sx={{ padding: 0 }}>
        <Box className="flex flex-row items-center justify-center min-h-full">
          <Box className="w-full min-h-full">
            {historyShow ? (
              <Box className="w-full p-3">
                <MenuRecentPages title="History" />
              </Box>
            ) : (
              children
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DrawerWithSearch;
