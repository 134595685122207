import React, { useEffect, useState } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';

const TableSearch = ({ setSearchKeyword, searchValue, ...props }) => {
  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchKeyword(value);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box className='w-full'>
      <TextField
        {...props}
        value={value}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon icon='ion:search-outline' />
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-input': {
            padding: '10px 0px!important',
          },
          '& .MuiInputAdornment-root': {
            marginTop: '0 !important',
            fontSize: '18px',
          },
          '& .MuiInputBase-root': {
            fontWeight: 400,
          },
        }}
      />
    </Box>
  );
};

export default TableSearch;
