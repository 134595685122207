// useLoader.ts
import { useDispatch, useSelector } from 'react-redux';
import { setCreateLoading, setEditLoading } from '../../store/loaderSlice.ts';

const useLoader = () => {
  const dispatch = useDispatch();
  const createLoading = useSelector((state) => state.loader.createLoading);
  const editLoading = useSelector((state) => state.loader.editLoading);

  const startCreateLoading = () => dispatch(setCreateLoading(true));
  const stopCreateLoading = () => dispatch(setCreateLoading(false));
  const startEditLoading = () => dispatch(setEditLoading(true));
  const stopEditLoading = () => dispatch(setEditLoading(false));

  return {
    createLoading,
    editLoading,
    startCreateLoading,
    stopCreateLoading,
    startEditLoading,
    stopEditLoading,
  };
};

export default useLoader;
