import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiInstance from '../apis/ApiService';
import axios from 'axios';

export const fetchMenu = createAsyncThunk(
  'menu/fetchMenu',
  async (token, { rejectWithValue }) => {
    try {
      const response = await apiInstance.NavMenu(
        `menus?pageSize=10&pageNumber=1`,
      );

      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const loginRequest = createAsyncThunk(
  'auth/login',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE}/auth/login`,(payload));//this was used for login to avoid our toast message from apilInstance
      if (response.status === 200) {
        const { token, currentUser } = response.data;
        await dispatch(fetchMenu());
        return { token, user: currentUser };
      } else {
        return rejectWithValue('Invalid credentials');
      }
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Message || error.message);
    }
  },
);

export const impersonateRequest = createAsyncThunk(
  'auth/impersonateRequest',
  async ({ values, token, navigate }, thunkAPI) => {
    try {
      const payload = {
        impersonatorUserEmail: values.email,
      };
      const rootUrl = process.env.REACT_APP_BASE;

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiInstance.impersonate(payload);

      if (response.status === 200) {
        const { token, currentUser } = response.data;

        navigate('/hrms/dashboard');
        return { token, user: currentUser };
      } else {
        return thunkAPI.rejectWithValue(
          response.data || 'Impersonation failed',
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data ||
          error.message ||
          'An error occurred during impersonation',
      );
    }
  },
);

export const userLogout = createAsyncThunk(
  'auth/logout',
  async (
    { user, token, navigate }: { user: any; token: string; navigate: any },
    { rejectWithValue },
  ) => {
    const rootUrl = process.env.REACT_APP_BASE;

    try {
      if (user?.impersonatorUserName !== null) {
        const response = await apiInstance.logoutImpersonate(
          `auth/impersonate/logout`,
          {},
        );

        if (response?.status === 200) {
          const { token, currentUser } = response.data;
          navigate('/hrms/dashboard');
          return { token, user: currentUser };
        }
      } else {
        await apiInstance.logout(`auth/logout`, {});

        navigate('/');
        return { token: null, user: null };
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const loginSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(loginRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(impersonateRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(impersonateRequest.fulfilled, (state, action) => {
        state.loading = false;
        //TODOstate.token = action.payload?.token;
        state.user = action.payload?.user;
      })
      .addCase(impersonateRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        state.token = action.payload?.token;
        state.user = action.payload?.user;
        state.loading = false;
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default loginSlice.reducer;
