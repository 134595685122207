import apiInstance from './ApiService';

//Remove Empty Parameters Not Having Value
const filterEmptyParams = (params) => {
  const filteredParams = { ...params };
  Object.keys(filteredParams).forEach((key) => {
    if (filteredParams[key] === undefined || filteredParams[key] === '') {
      delete filteredParams[key];
    }
  });
  return filteredParams;
};

class Crud_Service {
  //getall function
  getAll = async (url, params, callback) => {
    const qparms = filterEmptyParams(params);

    await apiInstance
      .getAll(url, qparms)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //getsingle function
  getSingle = async (url, params, callback) => {
    await apiInstance
      .getSingle(url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //create function
  create = async (url, data, callback) => {
    await apiInstance
      .create(url, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //update function
  update = async (url, params = {}, data, callback) => {
    await apiInstance
      .update(url, params, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //Patch update function
  patch = async (url, params = {}, data, callback) => {
    await apiInstance
      .patch(url, params, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //remove function
  remove = async (url, params, callback) => {
    await apiInstance
      .remove(url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
}

export default Crud_Service;
