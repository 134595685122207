import React, { useEffect } from 'react';
import Icons from '../../utils/Icon';
import {
  Box,
  Tooltip,
  Zoom,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Crud_Service from '../../apis/CrudService'
import useDateFormatter from './useDateFormatter';

const HeaderNotification = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const open = Boolean(anchorEl);
  const crud = new Crud_Service();
  const formatDate = useDateFormatter()

  useEffect(() => {
    getNotification();
  }, [])

  const getNotification = async () => {
    //TODO:This Api Url andd Id In Future We Need to Change Dynamic
    await crud.getAll('usernotifications/1?pageSize=50&pageNumber=1&sortDirection=desc', '', (err, res) => {
      if (res?.status === 200) {
        setNotification(res?.data?.data)
      } else {
        console.error('Something went wrong!', res);
      }
    })
  }

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title='Notification' arrow TransitionComponent={Zoom}>
        <IconButton sx={{ color: 'inherit', padding: "5px" }} onClick={handleClick}>
          <Badge color='secondary' variant='dot'>
            {Icons.bell}
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id={`notificationId`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { maxWidth: 320, maxHeight: 400, mt: '5px' },
        }}
        MenuListProps={{ sx: { pt: 0 } }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          className='w-full p-4 flex flex-row items-center justify-between'
          component='li'
          sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
        >
          <Box
            className='font-semibold flex flex-row items-center '
            sx={{ fontSize: '16px' }}
          >
            <Box className='text-2xl me-3'>{Icons.bell}</Box>
            Notifications
          </Box>
        </Box>

        {notification?.map((menu) => (
          <MenuItem
            key={menu?.userNotificationId}
            sx={{
              whiteSpace: 'normal',
              '&:hover': {
                bgcolor: 'background.light',
              },
            }}
            className='py-3'
          >
            <Box className='me-4'>
              <Avatar src={menu?.img} sx={{ width: '30px', height: '30px' }} />
            </Box>
            <Box>
              {menu?.notificationStatusName === 'UnRead' ? (
                <Box className='font-semibold'>{menu?.message}</Box>
              ) : (
                <Box className='opacity-70 font-light'>{menu?.message}</Box>
              )}
              <Box className='opacity-70 font-light'>{formatDate(menu?.createdDate)}</Box>
            </Box>
          </MenuItem>
        ))}

        <Box
          component='li'
          className='px-3 py-3 text-center'
          sx={{ borderTop: '1px solid', borderColor: 'border.main' }}
        >
          <Box
            component={NavLink}
            className='font-semibold'
            sx={{ color: 'primary.main' }}
          >
            View All Notification
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default HeaderNotification;
