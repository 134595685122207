import React from "react";
import { Box, Button } from "@mui/material";

interface ActionButtonsImpl {
  onSubmit: () => void;
  onCancel?: () => void;
  submitLoading?: boolean;
  cancelLoading?: boolean;
  submitText?: string | undefined;
  cancelText?: string | undefined;
  removeSpacing?: string | undefined;
  errorFields?: boolean;
}

const ActionButtons = (props: ActionButtonsImpl) => {
  const {
    onSubmit,
    onCancel,
    submitLoading,
    cancelLoading,
    submitText = "Submit",
    cancelText = "Cancel",
    removeSpacing,
    errorFields,
  } = props;

  return (
    <Box
      className={`flex flex-row gap-5 justify-end ${
        removeSpacing ? "" : "mt-5"
      }`}
      sx={{
        "& .MuiButtonBase-root": {
          minWidth: "120px",
          minHeight: "40px",
          fontWeight: 700,
        },
      }}
    >
      <Button
        variant="outlined"
        onClick={onCancel}
        disabled={submitLoading || cancelLoading}
      >
        {cancelLoading ? "Cancelling..." : cancelText}
      </Button>
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={submitLoading || cancelLoading || errorFields}
      >
        {submitLoading ? "Submitting..." : submitText}
      </Button>
    </Box>
  );
};

export default ActionButtons;
