import React from 'react';
import { Box, Stack } from '@mui/material';
import SidebarMenu from './helpers/SidebarMenu';
import PageMenu from './PageMenu';
import { Icon } from '@iconify/react';
import { Nav } from '../utils';
import SidebarSettingsDropdown from './helpers/SidebarSettingsDropdown';
import PerfectScrollbar from 'react-perfect-scrollbar';

const EssNav = {
  id: 1,
  name: 'ESS',
  icon: <Icon icon='solar:accessibility-broken' />,
  color: '#067e89',
  child: [
    {
      name: 'Profile',
      icon: <Icon icon='solar:chart-broken' />,
      path: '/essPortal/dashboard',
      children: null,
    },
    {
      name: 'Leave Request',
      icon: <Icon icon='streamline:database-refresh' />,
      count: '3',
      path: '/essPortal/leaveRequest',
      children: [],
    },
    {
      name: 'Leave Join',
      icon: <Icon icon='streamline:definition-search-book' />,
      path: '/essPortal/leaveJoin',
      children: null,
    },

    {
      name: 'Resignation',
      path: '/essPortal/resignation',
      icon: <Icon icon='streamline:bag-suitcase-1' />,
      children: [],
    },
    {
      name: 'Passport Request',
      path: '/essPortal/passportRequest',
      icon: <Icon icon='streamline:receipt-check' />,
    },

    {
      name: 'Document uploads',
      path: '/essPortal/documentUploads',
      icon: <Icon icon='simple-line-icons:calender' />,
      children: [],
    },
  ],
};

const Sidebar = (props) => {
  const { type, open } = props;
  const [activeMenuOpen, setActiveMenuOpen] = React.useState(false);
  const menu = localStorage.getItem('menu') || 0;

  const handleActiveMenuLeaveOpen = () => {
    setActiveMenuOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          minWidth: !open ? '220px' : '60px',
          flexBasis: !open ? '220px' : '60px',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          px: '0px',
          zIndex: 3,
          overflow: !open && 'hidden',
          borderRadius: !open && '0px 20px 20px 0px',
          bgcolor: '#122729',
          color: '#fff',
        }}
        className='sm:hidden'
      >
        <Box
          className='flex-1 min-h-0 h-full overflow-auto'
          sx={{ py: 1, px: 0.8 }}
        >
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <Stack direction='column' className='w-full' spacing={0.5}>
              {type === 'ESS'
                ? EssNav.child.map((item, i) => {
                    return (
                      item.name && (
                        <SidebarMenu
                          icon={item.icon}
                          key={i}
                          name={item.name}
                          to={item.path}
                          open={open}
                        />
                      )
                    );
                  })
                : Nav[menu].child?.map((item, i) => {
                    return (
                      item.name && (
                        <SidebarMenu
                          icon={item.icon}
                          key={i}
                          name={item.name}
                          to={item.path}
                          open={open}
                        />
                      )
                    );
                  })}
            </Stack>
          </PerfectScrollbar>
        </Box>
        <Box className='p-2'>
          <SidebarSettingsDropdown openToggle={open} />
        </Box>
      </Box>
      <PageMenu open={activeMenuOpen} leavePanel={handleActiveMenuLeaveOpen} />
    </>
  );
};

export default Sidebar;
