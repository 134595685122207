import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icons from '../../utils/Icon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Nav } from '../../utils';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../store/tabsSlice.ts';
import { useNavigate } from 'react-router-dom';

const HeaderAppDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  // const navMenu = useSelector((state) => state?.menu?.items);
  // const distinctModuleNames = [
  //   ...new Set(
  //     navMenu?.flatMap(item => item?.menus)
  //       ?.map(menu => menu?.moduleName)
  //   )
  // ];

  const typeMap = [
    'HRMS',
    'CRM',
    'ERP',
    'PR',
    'PreSales',
    'CAFM',
    'Procurement',
    'Financial',
    'Budgeting',
    'HSE',
    'Admin',
    'Project-Division',
    'FM-Division',
    'ELV',
    'QA/QC',
  ];
  const navigate = useNavigate();
  const menu = localStorage.getItem('menu') || 0;
  const setActiveMenu = (tab) => {
    localStorage.setItem('menu', tab);
    dispatch(setActiveTab(tab));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (index) => {
    setActiveMenu(index);
    navigate(`/${typeMap[index]}/dashboard`);
  };

  const MuiMenuItem = ({ name, icon, index, ...props }) => {
    return (
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          props.setActiveMenu(index);
        }}
        sx={{
          color: 'text.main',
          fontSize: 'inherit',
          py: 0,
          fontWeight: 600,
          borderRadius: '6px',
          gap: '15px',
          px: 1,
          '&:hover': {
            bgcolor: 'background.mainLight',
          },
        }}
        {...props}
      >
        {icon && (
          <Box
            sx={{
              fontSize: '25px',
              background: 'linear-gradient(to right, #f3f5af, #4ECDC4)',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              border: '1px solid',
              borderColor: 'primary.main',
              color: 'primary.main',
            }}
            className='flex justify-center items-center'
          >
            {icon}
          </Box>
        )}
        <Box>
          <Box className='uppercase opacity-85'>{name}</Box>
        </Box>
      </MenuItem>
    );
  };

  return (
    <Box className='sm:hidden'>
      <Button
        variant='text'
        sx={{
          textAlign: 'left',
          py: '2px',
          px: '10px',
          lineHeight: 1.2,
          color: 'inherit',
          borderRadius: '10px',
          fontWeight: 700,
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            width: 35,
            height: 35,
            borderRadius: '10px',
          }}
          className='flex flex-row items-center justify-center me-1 text-xl'
        >
          {Icons.apps}
        </Box>
        <Box>
          <Box className='uppercase'>{typeMap[menu]}</Box>
        </Box>
        <Box className='ms-2 text-lg opacity-70'>
          {open ? Icons.arrowUp : Icons.arrow}
        </Box>
      </Button>

      <Menu
        id={'app-dropdown'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            width: '430px',
            borderRadius: '5px',
          },
        }}
        MenuListProps={{
          sx: {
            padding: '0px',
            display: 'flex',
            flexWrap: 'wrap',
            '& li': {
              flexBasis: '33.33%',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: 'border.main',
              borderTop: 'none',
              borderLeft: 'none',
              borderRadius: '0px',
              py: 1,
              gap: '3px',
            },
          },
        }}
      >
        {Nav?.map(
          (menu, i) =>
            menu.name && (
              <MuiMenuItem
                index={i}
                setActiveMenu={handleMenuClick}
                key={i}
                icon={menu.icon}
                name={`${menu.name}`}
              />
            )
        )}
      </Menu>
    </Box>
  );
};

export default HeaderAppDropdown;
