import React from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/material";

const PageSubmenu = (props) => {
  const { menu } = props;

  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid",
          borderColor: "border.main",
          background: "linear-gradient(to right, #FFFFFF, #fff5f0, #ffffff)",
        }}
      >
        <Box component="ul" className="flex flex-wrap">
          {menu &&
            menu?.map((value, i) => {
              return (
                <Box component="li" key={i} className="inline-block">
                  <Box
                    component={NavLink}
                    to={value?.path}
                    sx={{
                      padding: "10px 12px",
                      display: "inline-block",
                      fontWeight: 400,
                      position: "relative",
                      "&:after": {
                        content: "''",
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        bgcolor: "primary.main",
                        height: "2px",
                        width: "0%",
                        transition: "0.5s all",
                      },
                      "&:hover": {
                        //bgcolor: "background.overlayTwo",
                        "&:after": {
                          width: "100%",
                        },
                      },
                      "&.active": {
                        fontWeight: 700,
                        "&:after": {
                          width: "100%",
                        },
                      },
                    }}
                  >
                    {value?.name}{" "}
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </>
  );
};

export default PageSubmenu;
