import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Button, Divider, Menu } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import MuiMenuItem from "../_mui/MuiMenuItem";
import Icons from "../../utils/Icon";
import { useTranslation } from "react-i18next";

const HeaderMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const AccountMenu = [
    {
      title: t("my_profile"),
      icon: Icons.profile,
      path: "",
    },

    {
      title: t("settings"),
      icon: Icons.settings,
      path: "",
    },

    {
      title: t("logout"),
      icon: Icons.logout,
      path: "",
    },
  ];

  return (
    <Box>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          p: 0,
          textTransform: "none",
          gap: "10px",
          fontSize: "inherit",
          color: "inherit",
          lineHeight: 1,
          textAlign: "left",
          fontWeight: 700,
        }}
      >
        <Avatar
          alt="Cindy Baker"
          src="/images/avatar.jpg"
          sx={{ width: "35px", height: "35px" }}
        />
        <Box className="sm:hidden">
          <Box>Hi, Ramesh Kumar</Box>
        </Box>
        {open ? (
          <Icon icon="flowbite:angle-up-outline" />
        ) : (
          <Icon icon="flowbite:angle-down-outline" />
        )}{" "}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { mt: "6px" } }}
        MenuListProps={{ sx: { pt: 0 } }}
        disableScrollLock={true}
      >
        <li className="w-full min-w-64 py-5">
          <Box
            className="w-full flex flex-col text-center justify-center items-center"
            sx={{ color: "text.dark" }}
          >
            <div className="inline-block mb-3">
              <Avatar
                alt="Cindy Baker"
                src="/images/avatar.jpg"
                sx={{ width: 65, height: 65 }}
              />
            </div>
            <div>
              <h3 className="font-bold">Ramesh Kumar</h3>
              <p className="opacity-70">ramesh.n@gmail.com</p>
            </div>
          </Box>
        </li>

        <Divider sx={{ mb: 1 }} />

        {AccountMenu?.map((menu, i) => (
          <MuiMenuItem
            onClick={() => navigate("/")}
            key={i}
            name={menu.title}
            icon={menu.icon}
          />
        ))}
      </Menu>
    </Box>
  );
};

export default HeaderMenu;
