import React, { useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { ListItemButton } from '@mui/material';
import { Icon } from '@iconify/react';
import ModalWithSearch from './helpers/ModalWithSearch';
import PerfectScrollbar from 'react-perfect-scrollbar';

const SearchModal = ({ openprop, onCloseprop }) => {
  const items = [
    {
      id: 1,
      name: 'Mui PickersUtilsProvider',
      pharse: 'Migration from @material-ui/pickers',
    },
    { id: 2, name: 'Basic Button', pharse: 'button' },
    { id: 3, name: 'Checkbox', pharse: 'Label' },
    { id: 4, name: 'Zoom', pharse: 'Transisition' },
    {
      id: 5,
      name: 'Fix z-index issues',
      pharse: 'Breaking changes in v5, part two: core components',
    },
    { id: 6, name: 'Microsoft Fluent', pharse: 'Theme Typography' },
  ];

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const NoSearch = () => {
    return (
      <Box
        className='flex flex-col items-center justify-center text-center'
        sx={{ minHeight: 400 }}
      >
        <Box className='text-6xl opacity-50 '>
          <Icon icon='fluent-mdl2:folder-search' />
        </Box>
        <p className=' text-xl mt-4 font-light'>
          <span className='opacity-50'>No results found </span>
          <span className='font-bold'>{searchTerm}</span>{' '}
        </p>
      </Box>
    );
  };

  const ItemLoop = ({ item }) => {
    return (
      <ListItemButton
        sx={{
          py: 1.5,
          '& .hide-shown': { opacity: 0, transition: '0.5s all' },
          '&:hover .hide-shown': { opacity: 1 },
          '&:hover': {
            bgcolor: 'background.light',
          },
        }}
      >
        <div className='text-2xl me-5 opacity-50'>
          <Icon icon='iconoir:page-search' />
        </div>
        <div className='flex-1'>
          <p className=' font-bold'>{item.name}</p>
          <p className='opacity-60 text-sm'>{item.pharse}</p>
        </div>
        <div className='text-xl me-2 hide-shown'>
          <Icon icon='lucide:external-link' />
        </div>
      </ListItemButton>
    );
  };

  return (
    <>
      <ModalWithSearch
        open={openprop}
        onClose={onCloseprop}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        disableRestoreFocus
        searchValue={searchTerm}
        searchHandleChange={handleSearchChange}
        searchPlaceholder='Search'
        modalWidth={550}
      >
        <Box
          sx={{
            height: '400px',
            overflow: 'auto',
          }}
        >
          <PerfectScrollbar>
            <ul>
              {items.length === 0 ? (
                <li>
                  <NoSearch />
                </li>
              ) : (
                items.map((item) => (
                  <li key={item.id}>
                    <ItemLoop item={item} />
                  </li>
                ))
              )}
            </ul>
          </PerfectScrollbar>
        </Box>
      </ModalWithSearch>
    </>
  );
};

SearchModal.propTypes = {
  onCloseprop: PropTypes.func,
  openprop: PropTypes.bool,
};

export default SearchModal;
