import React from "react";
import Box from "@mui/material/Box";
import DropDown from "./DropDown";

const HeaderHistoryDropdown = () => {
  return (
    <Box>
      <DropDown title="History" />
    </Box>
  );
};

export default HeaderHistoryDropdown;
