import React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material";
import { Icon } from "@iconify/react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { RecentPages } from "../../utils";

const MenuRecentPages = ({ title }) => {
  return (
    <Box className="w-full">
      <Box className="mb-2 font-bold text-lg px-4">{title}</Box>
      <MenuList className="p-0 w-full">
        {RecentPages.map((menu, i) => {
          return (
            <MenuItem
              className="py-2 flex flex-row"
              key={i}
              sx={{
                color: "inherit",
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: (theme) => alpha(theme.palette.text.dark, 0.05),
                },
              }}
            >
              <div className="me-2 text-2xl opacity-70">
                <Icon icon="material-symbols-light:history" />
              </div>
              <div className="flex-1 me-2">{menu.title}</div>
              <p className="font-light opacity-70">{menu.time}</p>
            </MenuItem>
          );
        })}
      </MenuList>
    </Box>
  );
};

export default MenuRecentPages;
