import React from 'react';
import Box from '@mui/material/Box';
import { Menu } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import MuiMenuItem from '../_mui/MuiMenuItem';
import { Icon } from '@iconify/react';
import SideBarIconButton from '../layout/SideBarIconButton.tsx';
import SideBarMenuButton from '../layout/SideBarMenuButton.tsx';

const AccountMenu = [
  {
    title: 'Holidays',
    icon: <Icon icon='fluent:beach-28-regular' />,
    path: '/fm/holidays',
  },

  {
    title: 'General Documents',
    icon: <Icon icon='fluent:document-bullet-list-20-regular' />,
    path: '/fm/general-documents',
  },

  {
    title: 'Approval Nodes',
    icon: <Icon icon='fluent:branch-fork-link-20-regular' />,
    path: '/fm/approval-node',
  },

  {
    title: 'Calendar Booking',
    icon: <Icon icon='fluent:calendar-24-regular' />,
    path: '/fm/calendar-booking',
  },
  {
    title: 'Form Builder',
    icon: <Icon icon='fluent:form-24-regular' />,
    path: '/fm/template',
  },
  {
    title: 'Questions Builder',
    icon: <Icon icon='fluent:chat-bubbles-question-32-regular' />,
    path: '/fm/questions',
  },
  {
    title: 'Mail Template',
    icon: <Icon icon='fluent:mail-inbox-all-24-regular' />,
    path: '/mailTemplate',
  },
  {
    title: 'Text Editor',
    icon: <Icon icon='fluent:panel-left-text-32-regular' />,
    path: '/text-editor',
  },
];

const SidebarSettingsDropdown = ({ openToggle }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className='w-full'>
      {!openToggle ? (
        <SideBarMenuButton
          onClick={handleClick}
          name='Extra Links'
          icon={<Icon icon='fluent:link-multiple-16-regular' />}
        />
      ) : (
        <SideBarIconButton
          name='Extra Links'
          icon={<Icon icon='fluent:link-multiple-16-regular' />}
          onClick={handleClick}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: "center",
        //   horizontal: "center",
        // }}
        // transformOrigin={{
        //   vertical: "center",
        //   horizontal: "center",
        // }}
        sx={{
          '& .MuiPaper-root': {
            '& .MuiList-root': {
              padding: '8px',
            },
          },
        }}
      >
        {AccountMenu?.map((menu, i) => (
          <MuiMenuItem
            onClick={() => navigate(menu.path)}
            key={i}
            name={menu.title}
            icon={menu.icon}
          />
        ))}
      </Menu>
    </Box>
  );
};

export default SidebarSettingsDropdown;
