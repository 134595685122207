import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const MainColors = {
  main: '#067E89',
  secondary: '#d48108',
  yellow: '#fcfc03',
  border: '#36435e',
  bgLight: '#272f42',
  textMain: '#AEAFB5',
  textDark: '#fff',
  textLight: '#8F93A6',
  white: '#1B202A',
  mainLight: '#eaf4f5',
  lightsm: '#2c313c',
  glassBg: 'rgba(255, 255, 255, 0.2)',
  blue: '#1d4ed8',
  blueLight: '#e9f6ff',
  mainLightBase: '#e0fcff',
  bgOverlay: 'rgb(0 0 0 / 36%)',
};

// Create a theme instance.
const dark = createTheme({
  palette: {
    primary: {
      main: MainColors.main,
    },
    secondary: {
      main: MainColors.secondary,
    },
    error: {
      main: red.A400,
    },
    border: {
      main: MainColors.border,
      glass: 'rgba(255, 255, 255, 0.3)',
    },
    yellow: {
      main: MainColors.yellow,
    },
    text: {
      main: MainColors.textMain,
      dark: MainColors.textDark,
      light: MainColors.textLight,
      white: MainColors.white,
    },
    background: {
      default: MainColors.white,
      light: MainColors.bgLight,
      white: MainColors.white,
      mainLight: MainColors.mainLight,
      mainLightBase: MainColors.mainLightBase,
      lightsm: MainColors.lightsm,
      glass: MainColors.glassBg,
      overlay: MainColors.bgOverlay,
      blueLight: MainColors.blueLight,
    },
    shadow: {
      main: '0 1px 10px rgba(0, 0, 0, 0.1)',
      glass: '0 4px 30px rgba(0, 0, 0, 0.1)',
    },
    shape: {
      main: 3,
    },
  },
  typography: {
    fontFamily: ['"Inter"', 'sans-serif'].join(','),
    allVariants: {
      color: MainColors.textMain,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#1B202A',
          fontSize: '14px',
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          fontSize: 'inherit',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontWeight: '500',
            borderRadius: '5px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontWeight: '500',
            borderRadius: '5px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            fontWeight: '500',
            borderRadius: '5px',
            paddingLeft: '15px',
            paddingRight: '15px',
            color: MainColors.textMain,
          },
        },
      ],
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          background: '#1B202A',
          boxShadow: 10,
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '5px',
          backgroundColor: MainColors.white,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          '&:hover': {
            background: MainColors.bgLight,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '13px',
          color: MainColors.white,
          backgroundColor: MainColors.textDark,
          fontWeight: 500,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        },
        arrow: {
          color: MainColors.textDark,
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            border: `1px solid ${MainColors.border}`,
            borderRadius: '8px',
            '& .MuiFormLabel-root': {
              fontSize: 'inherit',
            },
            '& .MuiInputBase-root': {
              background: MainColors.white,
              color: MainColors.textDark,
              fontSize: 'inherit',
              borderRadius: '8px',
              fontWeight: 600,
              '&:hover, &.Mui-focused': {
                backgroundColor: MainColors.white,
              },
              '&:before': {
                display: 'none',
              },
              '&:after': {
                display: 'none',
              },
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            '& .MuiFormLabel-root': {
              fontSize: 'inherit',
              marginTop: '-3px',
            },
            '& .MuiInputBase-root': {
              background: MainColors.white,
              color: MainColors.textDark,
              fontSize: 'inherit',
              borderRadius: '8px',
              fontWeight: 600,
              '&:hover, &.Mui-focused': {
                backgroundColor: MainColors.white,
              },

              '& .MuiInputBase-input': {
                padding: '12px 14px',
              },
            },
          },
        },
      ],
    },

    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          color: 'inherit',
          '& .MuiSvgIcon-root': {
            color: 'inherit',
            opacity: '0.5',
            '&:hover': {
              opacity: '1',
              color: 'inherit',
            },
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            fontSize: 'inherit',
            textTransform: 'inherit',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            borderRadius: '15px',
            minHeight: 'inherit',
            padding: '12px 15px',
            color: MainColors.textMain,
            marginBottom: '5px',
            '& svg': {
              marginBottom: 0,
              marginRight: '20px',
              fontSize: '25px',
            },
          },
          '& .MuiTabPanel-root': {
            padding: 0,
          },
          '& .Mui-selected': {
            // background: MainColors.main,
            // color: `${MainColors.white}!important`,
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: {
          fontSize: 'inherit',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
        },
        paper: {
          fontSize: 'inherit',
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgb(20 20 20 / 48%)',
          backdropFilter: 'blur(3px)',
        },
        invisible: {
          background: 'inherit',
          backdropFilter: 'blur(0px)',
        },
      },
    },
  },
});

export default dark;
