import React from 'react';
import { NavLink } from 'react-router-dom';
import SideBarIconButton from '../layout/SideBarIconButton.tsx';
import SideBarMenuButton from '../layout/SideBarMenuButton.tsx';

const SidebarMenu = ({ to, activeColor, icon, name, bg, open }) => {
  return (
    <>
      {!open ? (
        <SideBarMenuButton
          name={name}
          icon={icon}
          component={NavLink}
          to={to}
        />
      ) : (
        <SideBarIconButton
          name={name}
          icon={icon}
          component={NavLink}
          to={to}
        />
      )}
    </>
  );
};

export default SidebarMenu;
