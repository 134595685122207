import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

interface FmAutoCompleteProps {
  name: string;
  control: any;
  required?: boolean;
  label: string;
  options: Array<{ [key: string]: any }>;
  rules?: { [key: string]: any };
  displayField: string;
  value?: any;
  onChange?: (value: any) => void;
  valueKey: string;
  optionFields?: Array<string>;
  disabled?: boolean;
  defaultValue?: any;
  onChangeValue?: Boolean;
  readOnly?: Boolean;
  isMultiSelect?: boolean; // New prop for multi-select
}

const FmAutoComplete = (props: FmAutoCompleteProps) => {
  const {
    name,
    control,
    label,
    options,
    rules = {},
    displayField,
    onChange,
    onChangeValue,
    valueKey,
    optionFields = [],
    disabled,
    defaultValue,
    readOnly,
    isMultiSelect = false,
    required = false,
  } = props;

  const [inputValue, setInputValue] = useState('');

  // Filter options based on input value
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      optionFields.some((field) =>
        (option[field]?.toLowerCase() || '').includes(inputValue.toLowerCase())
      )
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            multiple={isMultiSelect}
            value={
              isMultiSelect
                ? options?.filter((opt) => field.value?.includes(opt[valueKey])) // for Multi-Select
                : options?.find((opt) => opt[valueKey] === field.value) || null // for Single-Select
            }
            defaultValue={defaultValue}
            onChange={(_event, newValue) => {
              if (isMultiSelect) {
                const selectedValues = newValue
                  ? newValue.map((item) => item[valueKey])
                  : [];
                field.onChange(selectedValues);
                if (onChange)
                  onChange(onChangeValue ? newValue : selectedValues);
              } else {
                field.onChange(newValue ? newValue[valueKey] : null);
                if (onChange)
                  onChange(
                    onChangeValue
                      ? newValue
                      : newValue
                      ? newValue[valueKey]
                      : null
                  );
              }
            }}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={options || []}
            getOptionLabel={(option) => option[displayField] || ''}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<>{label || 'Select Option'}</>}
                variant='filled'
                fullWidth
                error={!!error}
                disabled={disabled || false}
                required={required}
                aria-readonly={readOnly || false}
              />
            )}
            disabled={disabled}
            readOnly={readOnly || false}
            noOptionsText='No results found'
          />
          {error ? (
            <Box sx={{ marginTop: 0.2, color: 'error.main', fontSize: '8px' }}>
              {error?.message}
            </Box>
          ) : null}
        </>
      )}
    />
  );
};

export default FmAutoComplete;
