import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import ConfirmationButton from "./DeleteConfimation.tsx";

const TableActionButtons = (props) => {
  const { Actions, selectedRow } = props;

  return (
    <Box className="flex flex-row">
      {Actions?.map((item, i) => {
        if (item.name === "Delete") {
          return (
            <ConfirmationButton
              key={i}
              onConfirm={() => item.onClick(selectedRow, "TableActionButtons")}
              context="TableActionButtons"
              row={selectedRow}
              name={item.name}
              icon={item.icon}
            />
          );
        }
        return (
          <Tooltip key={i} title={item.name}>
            <>
              <IconButton
                onClick={(ev) => item.onClick(ev, item, selectedRow)}
                sx={{
                  py: 1,
                  color: item.danger ? "red" : "text.main",
                  "& svg": {
                    fontSize: "14px",
                  },
                }}
                title={item.name}
              >
                {item.icon}
              </IconButton>
            </>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default TableActionButtons;
