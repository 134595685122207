import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './i18n.js';
import { Provider } from 'react-redux';
import PageLoader from './components/page/PageLoader.jsx';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from './ThemeContext.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './store/store.ts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider>
      <React.Suspense fallback={<PageLoader />}>
        <App />
        <ToastContainer limit={2} autoClose={1000} closeOnClick />
      </React.Suspense>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
