
const RecentPages = [
    {        
        name: "CRM",
        title: "Task",
        time: "6:25 PM",
      },
    
      {        
        name: "HRMS",
        title: "Contact",
        time: "5:25 PM",
      },

      {        
        name: "CRM",
        title: "Appoinments",
        time: "4:25 PM",
      },

      {        
        name: "CRM",
        title: "PPM Issues",
        time: "3:25 PM",
      },

      {        
        name: "CRM",
        title: "PPM",
        time: "2:25 PM",
      },

      {        
        name: "ERP",
        title: "Account",
        time: "1:25 PM",
      },

      {        
        name: "ERP",
        title: "Contact",
        time: "1:25 PM",
      },

      

]

export default RecentPages;